import { BcThemeOptions } from "../themeBase";

export const DieLautenNachbarn: BcThemeOptions = {
  colors: {
    primary: "#D86Ebe",
    primaryLight: "#D86Ebe",
    primaryDark: "#D86Ebe",
    primaryContrast: "#fff",
    primaryLightContrast: "#fff",
    secondaryBackground: "#f2f2f2",
    bodyText: "#111",
    background: "#fff",
  },
  styles: {
    headerStyle: "centerBanner",
    authPageStyle: "default",
    logoStyle: "negative",
  },
  images: {
    banner: "",
    landing: "",
  },
  misc: {
    originUrl: "https://www.die-lauten-nachbarn.at/",
    topBarIconHeight: 0,
    landingPageIconHeight: 0,
  },
};
