import {
  Checkbox,
  createStyles,
  Grid,
  Input,
  Link,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import * as React from "react";
import * as intl from "react-intl-universal";
import { SectionLabel } from "../components";
import PasswordInput from "../components/PasswordInput";
import GenderSelect from "../components/GenderSelect";
import { useState } from "react";
import { Link as ReactLink, useHistory } from "react-router-dom";
import BirthdateInput from "./BirthdateInput";
import LoadingButton from "./LoadingButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: "auto",
      marginRight: "auto",
    },
    section: {
      paddingTop: "0px",
      paddingBottom: "12px",
    },
    login: {
      fontSize: theme.fontSizes.smallFont,
    },
    checkboxLabel: {
      fontSize: theme.fontSizes.smallFont,
    },
    forgotPassword: {
      color: theme.palette.secondary.main,
      textDecoration: "underline",
      fontSize: theme.fontSizes.verySmallFont,
      marginTop: 5,
    },
  }),
);

export interface Props {
  buttonLabel: string;
  buttonEnabled: (state: AuthPageFormState) => boolean;
  buttonProgress?: boolean;
  onButtonClick: (state: AuthPageFormState) => void;
  variant: "register" | "login";
}

export interface AuthPageFormState {
  email: string;
  password: string;
  birthdate: Date;
  gender: string;
  hasAcceptedAGBs: boolean;
  hasAcceptedPrivacyStatement: boolean;
}

export default function AuthPageForm(props: Props) {
  const { buttonLabel, buttonEnabled, onButtonClick, variant, buttonProgress } =
    props;

  const [state, setState] = useState<AuthPageFormState>({
    email: "",
    password: "",
    birthdate: undefined,
    gender: "",
    hasAcceptedAGBs: false,
    hasAcceptedPrivacyStatement: false,
  });

  const theme = useTheme();
  const classes = useStyles(theme);

  const history = useHistory();

  const handleChange = (name: any) => (event: any) => {
    // @ts-ignore
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleBirthdateChange = (date: Date) => {
    setState({
      ...state,
      birthdate: date,
    });
  };

  const toggleAGBs = () => {
    setState({
      ...state,
      hasAcceptedAGBs: !state.hasAcceptedAGBs,
    });
  };

  const togglePrivacyStatement = () => {
    setState({
      ...state,
      hasAcceptedPrivacyStatement: !state.hasAcceptedPrivacyStatement,
    });
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems="stretch"
        justifyContent="center"
        direction="column">
        {variant == "register" && (
          <Grid container className={classes.section} direction="column">
            <SectionLabel>{intl.get("birthyear.label")}</SectionLabel>
            <BirthdateInput
              value={state.birthdate}
              variant={"year"}
              onChange={handleBirthdateChange}
            />
          </Grid>
        )}

        {variant == "register" && (
          <Grid container className={classes.section} direction="column">
            <SectionLabel>{intl.get("gender.label")}</SectionLabel>
            <GenderSelect
              id="gender"
              value={state.gender}
              onChange={handleChange("gender")}
            />
          </Grid>
        )}
        <Grid container className={classes.section} direction="column">
          <SectionLabel>{intl.get("email.label")}</SectionLabel>
          <Input
            id="email"
            value={state.email}
            placeholder={intl.get("email.placeholder")}
            onChange={handleChange("email")}
          />
        </Grid>
        <Grid item container className={classes.section} direction="column">
          <SectionLabel>{intl.get("password.label")}</SectionLabel>
          <PasswordInput
            id="password-input"
            error={false}
            placeholder={intl.get("password.placeholder")}
            onChange={handleChange("password")}
          />
          {variant == "login" && (
            <ReactLink
              to={{
                pathname: "/forgotPassword",
                search: history.location.search,
              }}
              className={classes.forgotPassword}>
              {intl.get("login.forgot_password")}
            </ReactLink>
          )}
        </Grid>

        {variant == "register" && (
          <Grid
            item
            container
            alignItems="center"
            className={classes.checkboxLabel}>
            <Checkbox
              checked={state.hasAcceptedAGBs}
              onChange={() => toggleAGBs()}
              color="primary"
            />
            {intl.get("signup.accept_prefix")}&nbsp;
            <Link
              href="https://family-cherries.com/impressum"
              target="_blank"
              rel="noopener noreferrer">
              {intl.get("signup.accept_agb_link")}
            </Link>
          </Grid>
        )}

        {variant == "register" && (
          <Grid
            item
            container
            alignItems="center"
            style={{ marginTop: -12 }}
            className={[classes.checkboxLabel, classes.section].join(" ")}>
            <Checkbox
              checked={state.hasAcceptedPrivacyStatement}
              onChange={() => togglePrivacyStatement()}
              color="primary"
            />
            {intl.get("signup.accept_prefix")}&nbsp;
            <Link
              href="https://family-cherries.com/datenschutz"
              target="_blank"
              rel="noopener noreferrer">
              {intl.get("signup.accept_privacy_statement_link")}
            </Link>
          </Grid>
        )}
        <Grid item className={classes.section}>
          <LoadingButton
            onClick={() => {
              onButtonClick(state);
            }}
            loading={buttonProgress}
            disabled={!buttonEnabled(state)}
            buttonLabel={buttonLabel}
            fullWidth
          />
        </Grid>
      </Grid>
    </div>
  );
}
