import { MenuItem, Select, useTheme } from "@material-ui/core";
import React from "react";

interface Props {
  id?: string;
  defaultValue: string;
  placeholder: string;
  onChange: (event: any) => void;
  children?: any;
  readonly?: boolean;
}

export default function PlaceholderSelect(props: Props) {
  const { id, defaultValue, onChange, placeholder, children, readonly } = props;

  const theme = useTheme();
  const valueOrPlaceholder = defaultValue || "__placeholder__";

  return (
    <Select
      id={id}
      defaultValue={valueOrPlaceholder}
      onChange={onChange}
      readOnly={readonly}
      style={
        defaultValue
          ? {}
          : { color: "#c4c4c3", fontSize: theme.fontSizes.smallFont }
      }>
      {!defaultValue && (
        <MenuItem value={"__placeholder__"} disabled={true}>
          {placeholder}
        </MenuItem>
      )}
      {children}
    </Select>
  );
}
