import { BcThemeOptions } from "../themeBase";

export const EinerSchreitImmer: BcThemeOptions = {
  colors: {
    primary: "#d73e52",
    primaryLight: "#d73e52",
    primaryDark: "#d73e52",
    primaryContrast: "#fff",
    primaryLightContrast: "#fff",
    secondaryBackground: "#edf4f8", //"#ecf4f8",
    bodyText: "#333333",
    background: "#fff",
    indicatorProfile: "#4C7897",
    indicatorFavorite: "#4C7897",
  },
  styles: {
    headerStyle: "centerBanner",
    authPageStyle: "default",
    logoStyle: "negative",
  },
  images: {
    landing: "/themes/einerschreitimmerLogo.jpg",
    banner: "/themes/einerschreitimmerBanner.png",
  },
  misc: {
    originUrl: "https://einerschreitimmer.com",
    landingPageIconHeight: 120,
    topBarIconHeight: 100,
  },
};
