import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";
import { createBrowserHistory } from "history";
import * as React from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Router,
  Switch,
} from "react-router";
import * as intl from "react-intl-universal";
// @ts-ignore
import locale2 from "locale2";
import Landing from "./pages/LandingPage";
import withRoot from "./withRoot";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import Header from "./components/Header";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import AutoValidationPage from "./pages/AutoValidationPage";
import Progress from "./components/Progress";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import { Helmet } from "react-helmet";
import LogoutPage from "./pages/LogoutPage";
import AccountDeactivationPage from "./pages/AccountDeactivationPage";
import Footer from "./components/Footer";
import SmallMobileFooter from "./components/SmallMobileFooter";
import DateFnsUtils from "@date-io/date-fns";
import enLocale from "date-fns/locale/en-US";
import deLocale from "date-fns/locale/de-AT";

import "@fontsource/manrope/200.css";
import "@fontsource/manrope/300.css";
import "@fontsource/manrope/400.css";
import "@fontsource/manrope/500.css";
import "@fontsource/manrope/600.css";
import "@fontsource/manrope/700.css";
import "@fontsource/manrope/800.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/material-icons";
import "@fontsource/material-icons-round";
import "@fontsource/material-icons-outlined";

import { isMobile } from "react-device-detect";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MobileSettingsPage from "./pages/MobileSettingsPage";
import { isRunningInMobileApp } from "./utils/utils";

const germanLocalizations = require("./translations/de.json");

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: "100vw",
      height: "100%",
      zIndex: 1,
      minWidth: "320px",
      fontFamily: theme.fontFamily,
      color: theme.bodyTextColor,
      overscrollBehaviorY: "contain",
    },
    appFrame: {
      display: "flex",
      width: "100vw",
      height: "100%",
      flexDirection: "column",
      flexWrap: "nowrap",
      justifyContent: "flex-start",
      alignItems: "stretch",
      alignContent: "stretch",
      minHeight: "100vh",
      overscrollBehaviorY: "contain",
    },
    content: {
      backgroundColor: theme.authPages.background,
      width: "100vw",
      height: "100%",
      overflow: "hidden",
      flexGrow: 1,
      display: "flex",
    },
    progressContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    link: {
      color: theme.palette.secondary.main,
    },
  });

export interface Props
  extends RouteComponentProps<void>,
    WithStyles<typeof styles> {}

export interface State {
  initDone: boolean;
  topOffset: number;
}

const browserHistory = createBrowserHistory();

const locales = {
  en: germanLocalizations,
  de: germanLocalizations,
};

const datePickerLocales = {
  en: enLocale,
  de: deLocale,
};

class App extends React.Component<Props, State> {
  state = {
    initDone: false,
    topOffset: 0,
  };

  static determineLocale() {
    let localeString: string = locale2;
    if (locales[localeString]) {
      return localeString;
    }
    if (localeString.indexOf("-") !== -1) {
      localeString = localeString.substring(0, localeString.indexOf("-"));
      if (locales[localeString]) {
        return localeString;
      }
      return "en";
    }
    return "en";
  }

  componentDidMount() {
    this.loadLocales();
  }

  loadLocales() {
    if (!this.state.initDone) {
      intl
        .init({
          currentLocale: App.determineLocale(),
          locales,
        })
        .then(() => {
          // After loading CLDR locale data, start to render
          this.setState({ initDone: true });
        });
    }
  }

  renderRoutes() {
    return (
      <>
        <div className={this.props.classes.content}>
          <div style={{ height: this.state.topOffset }}>&nbsp;</div>
          <Switch>
            <Route exact={true} path="/landing" component={Landing} />

            <Route
              exact={true}
              path="/autovalidate"
              component={AutoValidationPage}
            />

            <Route exact={true} path="/login" component={LoginPage} />
            <Route exact={true} path="/logout" component={LogoutPage} />
            <Route
              exact={true}
              path="/deactivate"
              component={AccountDeactivationPage}
            />
            <Route exact={true} path="/signup" component={SignupPage} />
            <Route
              exact={true}
              path="/forgotPassword"
              component={ForgotPasswordPage}
            />
            <Route
              exact={true}
              path="/resetPassword"
              component={ResetPasswordPage}
            />
            {isMobile && (
              <Route
                exact={true}
                path="/settings"
                component={MobileSettingsPage}
              />
            )}

            <Route exact={true} path="/" component={Landing} />

            <Redirect
              to={{
                pathname: "/",
                search:
                  this.props.location && this.props.location.search
                    ? this.props.location.search
                    : "",
              }}
            />
          </Switch>
        </div>
      </>
    );
  }

  renderProgress() {
    const { classes } = this.props;

    if (!this.state.initDone) {
      return (
        <div className={classes.progressContainer}>
          <Progress />
        </div>
      );
    }
  }

  renderRouter() {
    const { classes } = this.props;

    if (this.state.initDone) {
      return (
        <Router history={browserHistory as any}>
          <div className={classes.root}>
            <div className={classes.appFrame}>
              <Header />
              {this.renderRoutes()}
              {!isMobile && !isRunningInMobileApp() && <Footer />}
              {isMobile && !isRunningInMobileApp() && <SmallMobileFooter />}
            </div>
          </div>
        </Router>
      );
    }
  }

  render() {
    return (
      this.state.initDone && (
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={datePickerLocales[App.determineLocale()] || enLocale}>
          <>
            <Helmet>
              <title>{intl.get(`app.name`)}</title>
              <link rel="canonical" href="https://family-cherries.com/events" />
            </Helmet>

            {this.renderProgress()}
            {this.renderRouter()}
          </>
        </MuiPickersUtilsProvider>
      )
    );
  }
}

// @ts-ignore
export default withRoot(withStyles(styles)(App));
