import { BcThemeOptions } from "../themeBase";

export const Reisetippscc: BcThemeOptions = {
  colors: {
    primary: "#4fa600",
    primaryLight: "#4fa600",
    primaryDark: "#4fa600",
    primaryContrast: "#ffd205",
    primaryLightContrast: "#ffd205",
    bodyText: "#0a0a0a",
    secondaryBackground: "#f6f7ec", //"linear-gradient(#eada8e, #eb9c44)",
    indicatorProfile: "#eb9c44",
    indicatorFavorite: "#ffd205",
    background: "#fff",
  },
  styles: {
    headerStyle: "centerBanner",
    authPageStyle: "default",
    logoStyle: "negative",
  },
  images: {
    landing: "/themes/reisetippscc.png",
    banner: "/themes/reisetippscc.png",
  },
  misc: {
    originUrl: "https://www.reisetipps.cc",
    landingPageIconHeight: 100,
    topBarIconHeight: 70,
  },
};
