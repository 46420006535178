import { Base64 } from "js-base64";

export function getParamValueInString(searchString: string, paramName: any) {
  if (searchString.indexOf("?") !== -1) {
    searchString = searchString.substring(searchString.indexOf("?") + 1);
  }
  const qArray = searchString.split("&"); // get key-value pairs
  for (let i = 0; i < qArray.length; i++) {
    const pArr = qArray[i].split("="); // split key and value
    if (pArr[0] === paramName) {
      const value = pArr[1];
      if (paramName === "redirect_uri") {
        return decodeURIComponent(value);
      }
      return value;
    }
  }
  return null;
}

export function getParamValue(paramName: any) {
  return getParamValueInString(window.location.search.substring(1), paramName);
}

export interface ThemeConfigurationParameter {
  parsedConfiguration: ThemeConfiguration;
  rawConfiguration: string;
}

export interface ThemeConfiguration {
  configuration_variation: string;
  primaryColor: string;
  primaryContrastColor: string;
  primaryLightColor: string;
  navigationBarColor?: string;
  highlightColor?: string;
  cherriesLogoVariant: string;
  logoURL: string;
  imprintURL?: string;
  privacyURL?: string;
  blogURL: string;
  blogName: string;
  apiKey: string;

  // Only available for full variant
  primaryLightContrastColor?: string;
  primaryDarkColor?: string;
  browserThemeColor?: string;
  bodyTextColor?: string;
  indicatorProfileColor?: string;
  indicatorFavoriteColor?: string;
  topBarBackgroundColor?: string;
  topBarContrastColor?: string;
  secondaryBackgroundColor?: string;
  backgroundColor?: string;
}

export function getThemeConfigurationParam(): ThemeConfigurationParameter | null {
  const redirectUri = getParamValue("redirect_uri");
  if (redirectUri) {
    const configuration = getParamValueInString(redirectUri, "configuration");

    if (configuration) {
      const decodedConfiguration = Base64.decode(configuration);
      if (decodedConfiguration) {
        const parsedConfiguration = JSON.parse(decodedConfiguration);
        if (
          parsedConfiguration &&
          parsedConfiguration.configuration_variation &&
          (parsedConfiguration.configuration_variation === "v1_simple" ||
            parsedConfiguration.configuration_variation === "v1_full")
        ) {
          return {
            rawConfiguration: configuration,
            parsedConfiguration: parsedConfiguration,
          };
        }
      }
    }
  }
  return null;
}

export function isRunningInMobileApp() {
  // @ts-ignore
  if (window.CherriesBridge) {
    return true;
  }
  // @ts-ignore
  const wk = window.webkit;
  if (wk && wk.messageHandlers && wk.messageHandlers.cherries) {
    return true;
  }
  return getParamValue("mobile") !== null;
}
