import * as React from "react";
import { DatePicker } from "@material-ui/pickers";
import intl from "react-intl-universal";
import { useTheme } from "@material-ui/core";

interface Props {
  value: Date;
  onChange: (newValue: Date) => void;
  endAdornment?: any;
  readonly?: boolean;
  variant: "year" | "month";
}

export default function BirthdateInput(props: Props) {
  const { value, onChange, endAdornment, readonly, variant } = props;

  const theme = useTheme();

  return (
    <DatePicker
      views={variant == "year" ? ["year"] : ["year", "month"]}
      variant={"dialog"}
      value={value ? value : null}
      InputProps={{
        endAdornment,
        style: value
          ? {}
          : {
              color: "#c4c4c3",
              fontSize: theme.fontSizes.smallFont,
            },
      }}
      disabled={readonly}
      onChange={onChange}
      minDate={new Date(1900, 1, 1)}
      maxDate={new Date()}
      emptyLabel={intl.get(
        variant === "year" ? "birthyear.placeholder" : "birthdate.placeholder",
      )}
    />
  );
}
