import { BcThemeOptions } from "../themeBase";

export const MothersFinest: BcThemeOptions = {
  colors: {
    primary: "#f7e8eb",
    primaryLight: "#f7e8eb",
    primaryDark: "#eac4cc",
    primaryContrast: "#79767a",
    primaryLightContrast: "#79767a",
    bodyText: "#000",
    secondaryBackground: "#fff",
    background: "#fff",
    indicatorProfile: "#f00",
    indicatorFavorite: "#fff",
  },
  styles: {
    headerStyle: "centerBanner",
    authPageStyle: "flat",
    logoStyle: "negative",
  },
  images: {
    landing: "/themes/mothersFinestLogo.jpeg",
    banner: "/themes/mothersFinestLogo.jpeg",
  },
  misc: {
    originUrl: "https://mothersfinest.me",
    landingPageIconHeight: 80,
    topBarIconHeight: 70,
  },
};
