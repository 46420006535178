import { MenuItem } from "@material-ui/core";
import { Gender } from "../model/gender";
import intl from "react-intl-universal";
import React from "react";
import PlaceholderSelect from "./PlaceholderSelect";

interface Props {
  id?: string;
  value: string;
  onChange: (event: any) => void;
  readonly?: boolean;
}

export default function GenderSelect(props: Props) {
  const { id, value, onChange, readonly } = props;

  return (
    <PlaceholderSelect
      id={id}
      defaultValue={value}
      onChange={onChange}
      readonly={readonly}
      placeholder={intl.get("gender.placeholder")}>
      <MenuItem value={Gender.FEMALE}>{intl.get("gender.female")}</MenuItem>
      <MenuItem value={Gender.MALE}>{intl.get("gender.male")}</MenuItem>
      <MenuItem value={Gender.DIVERSE}>{intl.get("gender.diverse")}</MenuItem>
    </PlaceholderSelect>
  );
}
