import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import { useHistory } from "react-router";
import intl from "react-intl-universal";
import MobileToolbar from "../components/MobileToolbar";
import { PageTracker } from "../utils/pageTracker";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {},
    listItem: {
      marginLeft: 15,
      marginRight: 15,
      paddingTop: 20,
      paddingBottom: 20,
      borderTop: "1px solid " + theme.palette.divider,
    },
    link: {
      textDecoration: "none",
      fontSize: theme.fontSizes.mediumFont,
      color: theme.bodyTextColor,
    },
  }),
);

interface Props {}

export default function MobileSettingsPage(props: Props) {
  const history = useHistory();

  const theme = useTheme();
  const classes = useStyles(theme);

  const onBackClicked = () => {
    history.goBack();
  };

  return (
    <Grid container direction={"column"}>
      <PageTracker />
      <Grid item className={classes.toolbar}>
        <MobileToolbar
          title={intl.get("settings.toolbar_title")}
          onBackClicked={onBackClicked}
        />
      </Grid>
      <Grid item className={classes.listItem}>
        <a
          className={classes.link}
          href="https://family-cherries.com"
          target="_blank"
          rel="noopener noreferrer">
          {intl.get("settings.contact")}
        </a>
      </Grid>
      <Grid item className={classes.listItem}>
        <a
          className={classes.link}
          href="https://family-cherries.com/impressum"
          target="_blank"
          rel="noopener noreferrer">
          {intl.get("settings.tos")}
        </a>
      </Grid>
      <Grid item className={classes.listItem}>
        <a
          className={classes.link}
          href="https://family-cherries.com/datenschutz"
          target="_blank"
          rel="noopener noreferrer">
          {intl.get("settings.privacy")}
        </a>
      </Grid>
      <Grid item className={classes.listItem}>
        <a
          className={classes.link}
          href="https://family-cherries.com/ueber_uns"
          target="_blank"
          rel="noopener noreferrer">
          {intl.get("settings.about")}
        </a>
      </Grid>
    </Grid>
  );
}
