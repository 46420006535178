import {
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FacebookLogin from "@greatsumini/react-facebook-login";
import AppleIcon from "../assets/icons/apple.svg";
import * as React from "react";
import { getParamValue, isRunningInMobileApp } from "../utils/utils";
import {
  signInWithApple,
  signInWithFacebook,
  signInWithGoogle,
} from "../actions/auth";
import { Error } from "../model/model";
import { useEffect } from "react";
import { ColorSvg } from "./ColorSvg";
import Facebook from "../assets/icons/Facebook";
import Apple from "../assets/icons/Apple";
import Google from "../assets/icons/Google";

interface Props {
  onError: (error?: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      paddingTop: "0px",
      paddingBottom: "12px",
    },
    socialLoginButton: {
      height: 46,
      width: 46,
      marginLeft: 7.5,
      marginRight: 7.5,
      "&:disabled": {
        opacity: 0.45,
      },
    },
    socialLoginIcon: {
      height: 46,
      width: 46,
      color: theme.palette.primary.main,
    },
    googleCookieInfo: {
      color: theme.palette.secondary.main,
      marginTop: 14,
      marginBottom: 10,
      fontSize: theme.fontSizes.smallFont,
      fontFamily: theme.fontFamily,
      textAlign: "center",
    },
  }),
);

export function SocialLoginButtons(props: Props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { onError } = props;

  let isRunningIniOSApp = false;
  let isRunningInAndroidApp = false;

  if (isRunningInMobileApp()) {
    // @ts-ignore
    if (window.CherriesBridge) {
      isRunningInAndroidApp = true;
    } else {
      // @ts-ignore
      const wk = window.webkit;
      if (wk && wk.messageHandlers && wk.messageHandlers.cherries) {
        isRunningIniOSApp = true;
      }
    }
  }

  // @ts-ignore
  window.handleCustomGoogleResponse = (response) => {
    const { credential } = response;
    if (credential) {
      const redirectUri = getParamValue("redirect_uri")!;
      const codeChallenge = getParamValue("code_challenge")!;
      signInWithGoogle(
        credential,
        redirectUri,
        codeChallenge,
        isRunningIniOSApp,
        isRunningInAndroidApp,
        (authorizationCode: string) => {
          if (redirectUri.indexOf("?") !== -1) {
            window.location.replace(
              `${redirectUri}&authorization_code=${authorizationCode}`,
            );
          } else {
            window.location.replace(
              `${redirectUri}?authorization_code=${authorizationCode}`,
            );
          }
        },
        (error: Error | null) => {
          onError(error);
        },
      );
    }
  };

  // @ts-ignore
  window.handleCustomAppleResponse = (response) => {
    // @ts-ignore
    const redirectUri = getParamValue("redirect_uri")!;
    const codeChallenge = getParamValue("code_challenge")!;

    const code = response.code;
    const idToken = response.id_token;

    // @ts-ignore
    signInWithApple(
      code,
      idToken,
      redirectUri,
      codeChallenge,
      isRunningIniOSApp,
      isRunningInAndroidApp,
      (authorizationCode: string) => {
        if (redirectUri.indexOf("?") !== -1) {
          window.location.replace(
            `${redirectUri}&authorization_code=${authorizationCode}`,
          );
        } else {
          window.location.replace(
            `${redirectUri}?authorization_code=${authorizationCode}`,
          );
        }
      },
      (error: Error | null) => {
        onError(error);
      },
    );
  };

  useEffect(() => {
    document.addEventListener("AppleIDSignInOnSuccess", (data) => {
      // @ts-ignore
      const detail = data.detail;
      const code = detail.authorization.code;
      const idToken = detail.authorization.id_token;

      // @ts-ignore
      window.handleCustomAppleResponse({
        code: code,
        id_token: idToken,
      });
    });
    document.addEventListener("AppleIDSignInOnFailure", (error) => {
      onError();
    });
  });

  const googleClientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID!;

  // @ts-ignore
  window.handleCustomFacebookResponse = (response: any) => {
    const fbToken = response.accessToken;
    if (fbToken) {
      const redirectUri = getParamValue("redirect_uri")!;
      const codeChallenge = getParamValue("code_challenge")!;
      signInWithFacebook(
        fbToken,
        redirectUri,
        codeChallenge,
        (authorizationCode: string) => {
          if (redirectUri.indexOf("?") !== -1) {
            window.location.replace(
              `${redirectUri}&authorization_code=${authorizationCode}`,
            );
          } else {
            window.location.replace(
              `${redirectUri}?authorization_code=${authorizationCode}`,
            );
          }
        },
        (error: Error | null) => {
          onError(error);
        },
      );
    }
  };

  if (isRunningIniOSApp) {
    return (
      <Box>
        <Grid
          container
          className={classes.section}
          direction="row"
          alignItems={"center"}
          justifyContent="center">
          <IconButton
            onClick={() => {
              // @ts-ignore
              const wk = window.webkit;
              if (wk && wk.messageHandlers && wk.messageHandlers.cherries) {
                // @ts-ignore
                wk.messageHandlers.cherries.postMessage({
                  nativeLoginType: "facebook",
                });
              }
            }}
            className={classes.socialLoginButton}>
            <ColorSvg svg={Facebook} className={classes.socialLoginIcon} />
          </IconButton>

          <IconButton
            className={classes.socialLoginButton}
            onClick={() => {
              // @ts-ignore
              const wk = window.webkit;
              if (wk && wk.messageHandlers && wk.messageHandlers.cherries) {
                // @ts-ignore
                wk.messageHandlers.cherries.postMessage({
                  nativeLoginType: "google",
                });
              }
            }}>
            <ColorSvg svg={Google} className={classes.socialLoginIcon} />
          </IconButton>

          <IconButton
            className={classes.socialLoginButton}
            onClick={() => {
              // @ts-ignore
              const wk = window.webkit;
              if (wk && wk.messageHandlers && wk.messageHandlers.cherries) {
                // @ts-ignore
                wk.messageHandlers.cherries.postMessage({
                  nativeLoginType: "apple",
                });
              }
            }}>
            <ColorSvg
              svg={Apple}
              className={classes.socialLoginIcon}
              style={{ gridRow: 1, gridColumn: 1 }}
            />
          </IconButton>
        </Grid>
      </Box>
    );
  } else if (isRunningInAndroidApp) {
    return (
      <Box>
        <Grid
          container
          className={classes.section}
          direction="row"
          alignItems={"center"}
          justifyContent="center">
          <IconButton
            onClick={() => {
              // @ts-ignore
              window.CherriesBridge.performNativeLogin("facebook");
            }}
            className={classes.socialLoginButton}>
            <ColorSvg svg={Facebook} className={classes.socialLoginIcon} />
          </IconButton>

          <IconButton
            className={classes.socialLoginButton}
            onClick={() => {
              // @ts-ignore
              window.CherriesBridge.performNativeLogin("google");
            }}>
            <ColorSvg svg={Google} className={classes.socialLoginIcon} />
          </IconButton>

          <IconButton
            className={classes.socialLoginButton}
            onClick={() => {
              // @ts-ignore
              window.CherriesBridge.performNativeLogin("apple");
            }}>
            <ColorSvg
              svg={Apple}
              className={classes.socialLoginIcon}
              style={{ gridRow: 1, gridColumn: 1 }}
            />
          </IconButton>
        </Grid>
      </Box>
    );
  } else {
    return (
      <Box>
        <Grid
          container
          className={classes.section}
          direction="row"
          alignItems={"center"}
          justifyContent="center">
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
            autoLoad={false}
            language={"de_DE"}
            fields="name,email"
            onSuccess={(response) => {
              // @ts-ignore
              window.handleCustomFacebookResponse(response);
            }}
            onFail={() => {}}
            onProfileSuccess={() => {}}
            render={({ onClick }) => (
              <IconButton
                onClick={onClick}
                className={classes.socialLoginButton}>
                <ColorSvg svg={Facebook} className={classes.socialLoginIcon} />
              </IconButton>
            )}
          />
          <div
            id="g_id_onload"
            data-context="signin"
            data-ux_mode="popup"
            data-callback="handleCustomGoogleResponse"
            data-client_id={googleClientId}
            data-auto_prompt="false"
          />

          <div
            className="g_id_signin"
            data-type="icon"
            data-shape="circle"
            data-theme="outline"
            data-text="signin"
            data-size="large"
          />

          <IconButton className={classes.socialLoginButton}>
            <div style={{ display: "grid" }}>
              <img
                src={AppleIcon}
                id="appleid-signin"
                data-type="sign in"
                style={{ gridRow: 1, gridColumn: 1, opacity: 0, zIndex: 5 }}
              />
              <ColorSvg
                svg={Apple}
                className={classes.socialLoginIcon}
                style={{ gridRow: 1, gridColumn: 1 }}
              />
            </div>
          </IconButton>
        </Grid>
      </Box>
    );
  }
}
