import {
  Button,
  createStyles,
  Grid,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import * as intl from "react-intl-universal";
import { ErrorView, SectionLabel } from "../components";
import { Helmet } from "react-helmet";
import { getParamValue } from "../utils/utils";
import { Error } from "../model/model";
import { forgotPassword } from "../actions/auth";
import { Link } from "react-router-dom";
import { PageTracker } from "../utils/pageTracker";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 640,
      paddingTop: 20,
      paddingRight: 10,
      paddingLeft: 10,
      marginLeft: "auto",
      marginRight: "auto",
    },
    title: {},
    subTitle: {
      marginBottom: 10,
    },
    section: {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    textField: {
      margin: 0,
    },
    button: {
      paddingTop: "10px",
      paddingBottom: "10px",
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignItems: "flex-start",
      [theme.breakpoints.down("xs")]: {
        alignItems: "stretch",
      },
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
  });

export interface Props
  extends RouteComponentProps<void>,
    WithStyles<typeof styles> {}

export interface State {
  email: String;
  failed: boolean;
  error: Error | null;
  finished: boolean;
}

class ForgotPasswordPage extends React.Component<Props, State> {
  state = {
    email: "",
    failed: false,
    error: null,
    finished: false,
  };

  handleChange = (name: string) => (event: any) => {
    if (this.state.failed) {
      this.setState({ failed: false, error: null });
    }
    // @ts-ignore
    this.setState({
      [name]: event.target.value,
    });
  };

  forgotPassword = () => {
    const redirectUri = getParamValue("redirect_uri")!;
    const codeChallenge = getParamValue("code_challenge")!;

    forgotPassword(
      this.state.email,
      redirectUri,
      codeChallenge,
      () => {
        this.setState({ finished: true });
      },
      (error: Error | null) => {
        this.setState({ failed: true, error: error });
      },
    );
  };

  render() {
    const { classes, location } = this.props;

    if (this.state.finished) {
      return (
        <div className={classes.root}>
          <Typography variant={"h2"} className={classes.title}>
            {intl.get("forgotPassword.title")}
          </Typography>
          <Typography variant={"h3"} className={classes.subTitle}>
            {intl.get("forgotPassword.email_sent")}
          </Typography>
        </div>
      );
    }
    return (
      <div className={classes.root}>
        <PageTracker />
        <Helmet>
          <title>
            {intl.get(`seo.forgotpassword.title`) +
              " | " +
              intl.get(`app.name`)}
          </title>
        </Helmet>
        <Typography variant={"h2"} className={classes.title}>
          {intl.get("forgotPassword.title")}
        </Typography>
        <Typography variant={"h3"} className={classes.subTitle}>
          {intl.get("forgotPassword.subTitle")}
        </Typography>

        {this.state.failed && (
          <ErrorView
            error={this.state.error}
            defaultErrorMessage={intl.get("error.generic")}
          />
        )}

        <Grid container justifyContent="center" direction="column">
          <Grid item container className={classes.section} direction="column">
            <SectionLabel>{intl.get("email.label")}</SectionLabel>
            <TextField
              error={this.state.failed}
              id="email"
              className={classes.textField}
              value={this.state.email}
              onChange={this.handleChange("email")}
              margin="normal"
            />
          </Grid>
          <Grid item container className={classes.section}>
            <Link
              to={{ pathname: "/login", search: location.search }}
              className={classes.link}>
              {intl.get("signup.jump_to_login")}
            </Link>
          </Grid>
          <Grid item>
            <div className={classes.button}>
              <Button
                onClick={this.forgotPassword}
                disabled={this.state.email.length === 0}>
                {intl.get("forgotPassword.button")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ForgotPasswordPage);
