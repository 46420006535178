import { BcThemeOptions } from "../themeBase";

export const Margute: BcThemeOptions = {
  colors: {
    primary: "#d78999",
    primaryLight: "#eddce0",
    primaryDark: "#d78999",
    primaryContrast: "#fff",
    primaryLightContrast: "#000",
    secondaryBackground: "#fff",
    indicatorProfile: "#f00",
    bodyText: "#000",
    background: "#fff",
  },
  styles: {
    headerStyle: "centerBanner",
    authPageStyle: "flat",
    logoStyle: "negative",
  },
  images: {
    landing: "/themes/marguteLogo.png",
    banner: "/themes/marguteLogo.png",
  },
  misc: {
    originUrl: "https://margute.com",
    landingPageIconHeight: 80,
    topBarIconHeight: 80,
  },
};
