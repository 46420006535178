import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import Grid from "@material-ui/core/Grid";
import * as intl from "react-intl-universal";
import * as EmailValidator from "email-validator";
import { Helmet } from "react-helmet";
import { ErrorView } from "../components";
import { getParamValue } from "../utils/utils";
import { Error } from "../model/model";
import { login } from "../actions/auth";
import TextDivider from "../components/TextDivider";
import { SocialLoginButtons } from "../components/SocialLoginButtons";
import AuthPageLayout from "../components/AuthPageLayout";
import AuthPageForm, { AuthPageFormState } from "../components/AuthPageForm";
import clsx from "clsx";
import { PageTracker } from "../utils/pageTracker";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginLeft: "auto",
      marginRight: "auto",
    },
    section: {
      paddingTop: "0px",
      paddingBottom: "12px",
    },
    register: {
      fontSize: theme.fontSizes.smallFont,
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    forgotPassword: {
      color: theme.palette.secondary.main,
      textDecoration: "underline",
      fontSize: theme.fontSizes.verySmallFont,
      marginTop: 5,
    },
  });

export interface Props
  extends RouteComponentProps<void>,
    WithStyles<typeof styles> {}

export interface State {
  redirectToReferrer: Boolean;
  failed: Boolean;
  progress: boolean;
  error: Error | null;
}

class LoginPage extends React.Component<Props, State> {
  state = {
    redirectToReferrer: false,
    failed: false,
    progress: false,
    error: null,
  };

  hasValidLoginData = (state: AuthPageFormState) => {
    const emailValid = this.validateEmail(state.email);
    const passwordValid = this.validatePassword(state.password) && emailValid;
    return emailValid && passwordValid;
  };

  validateEmail = (email: any) => {
    if (email.length > 0) {
      return EmailValidator.validate(email);
    }
    return email.length > 0;
  };

  validatePassword = (password: any) => {
    return password.length > 0;
  };

  componentDidMount() {
    const error = getParamValue("error");

    if (error && !this.state.error) {
      this.setState({
        failed: true,
        error: { error: error, error_code: error },
      });
    }
  }

  login = (state: AuthPageFormState) => {
    const redirectUri = getParamValue("redirect_uri")!;
    const codeChallenge = getParamValue("code_challenge")!;

    this.setState({ failed: true, error: null, progress: true });

    login(
      state.email,
      state.password,
      redirectUri,
      codeChallenge,
      (authorizationCode: string) => {
        if (redirectUri.indexOf("?") !== -1) {
          window.location.replace(
            `${redirectUri}&authorization_code=${authorizationCode}`,
          );
        } else {
          window.location.replace(
            `${redirectUri}?authorization_code=${authorizationCode}`,
          );
        }
      },
      (error: Error | null) => {
        this.setState({ failed: true, error, progress: false });
      },
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.root}>
          <PageTracker />
          <Helmet>
            <title>{`${intl.get("seo.login.title")} | ${intl.get(
              `app.name`,
            )}`}</title>
          </Helmet>

          <AuthPageLayout
            title={intl.get("login.title")}
            subTitle={intl.get("login.subTitle")}
            onUseAsGuestClicked={() => {
              this.props.history.replace(
                `/autovalidate?redirect_uri=${getParamValue(
                  "redirect_uri",
                )}&code_challenge=${getParamValue("code_challenge")}${
                  getParamValue("theme") !== null
                    ? `&theme=${getParamValue("theme")}`
                    : ""
                }`,
              );
            }}>
            <AuthPageForm
              buttonLabel={intl.get("login.button")}
              buttonEnabled={this.hasValidLoginData}
              onButtonClick={this.login}
              buttonProgress={this.state.progress}
              variant={"login"}
            />

            {this.state.failed && (
              <div className={classes.section}>
                <ErrorView
                  error={this.state.error}
                  defaultErrorMessage={intl.get("login.error")}
                />
              </div>
            )}

            <Grid
              container
              className={clsx(classes.section, classes.register)}
              justifyContent={"center"}>
              {intl.get("login.no_account_yet")}&nbsp;
              <a
                className={classes.link}
                href={`/signup?redirect_uri=${getParamValue(
                  "redirect_uri",
                )}&code_challenge=${getParamValue("code_challenge")}${
                  getParamValue("theme") !== null
                    ? `&theme=${getParamValue("theme")}`
                    : ""
                }`}>
                {intl.get("login.register")}
              </a>
            </Grid>

            <Grid item style={{ paddingTop: 26 - 12, paddingBottom: 26 }}>
              <TextDivider text={intl.get("or")} darkText={true} />
            </Grid>

            <Grid item>
              <SocialLoginButtons
                onError={(error: any) => {
                  this.setState({
                    ...this.state,
                    failed: true,
                    error: error,
                    progress: false,
                  });
                }}
              />
            </Grid>
          </AuthPageLayout>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(LoginPage);
