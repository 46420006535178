import { BcThemeOptions } from "../themeBase";

export const HappyMomDiary: BcThemeOptions = {
  colors: {
    primary: "#abaaaa",
    primaryLight: "#abaaaa",
    primaryDark: "#abaaaa",
    primaryContrast: "#fff",
    primaryLightContrast: "#fff",
    secondaryBackground: "#fff",
    indicatorProfile: "#bf899a",
    indicatorFavorite: "#fff",
    bodyText: "#424242",
    background: "#fff",
  },
  styles: {
    headerStyle: "centerBanner",
    authPageStyle: "flat",
    logoStyle: "negative",
  },
  images: {
    landing: "/themes/happymomdiary.png",
    banner: "/themes/happymomdiary.png",
  },
  misc: {
    originUrl: "https://happymomdiary.eu",
    landingPageIconHeight: 0,
    topBarIconHeight: 80,
  },
};
