import axios from "axios";
import * as intl from "react-intl-universal";
import { Error, mapErrorFromException } from "../model/model";
import { v5 as uuidv5 } from "uuid";

export const signup = async (
  email: string,
  password: string,
  birth_year: number | undefined,
  gender: string | undefined,
  redirectUri: string,
  code_challenge: string,
  completion: (authorizationCode: string) => void,
  onError: (error: Error | null) => void,
) => {
  try {
    const res = await axios.post(
      "/api/auth2/b2c/signup",
      {
        email,
        password,
        scope: "b2c",
        sessionDeviceFingerprint: generateSimpleDeviceFingerprint(),
        redirect_uri: redirectUri,
        code_challenge: code_challenge,
        birth_year: birth_year,
        gender,
      },
      { withCredentials: true },
    );

    if (res.data.authorization_code) {
      completion(res.data.authorization_code);
    } else {
      onError(null);
    }
  } catch (error) {
    onError(mapErrorFromException(error));
  }
};

export const signInWithFacebook = async (
  fbToken: string,
  redirectUri: string,
  code_challenge: string,
  completion: (authorizationCode: string) => void,
  onError: (error: Error | null) => void,
) => {
  try {
    const res = await axios.post(
      "/api/auth2/b2c/facebook",
      {
        access_token: fbToken,
        sessionDeviceFingerprint: generateSimpleDeviceFingerprint(),
        scope: "b2c",
        redirect_uri: redirectUri,
        code_challenge: code_challenge,
      },
      { withCredentials: true },
    );

    if (res.data.authorization_code) {
      completion(res.data.authorization_code);
    } else {
      onError(null);
    }
  } catch (error) {
    onError(mapErrorFromException(error));
  }
};

export const signInWithGoogle = async (
  credential: any,
  redirectUri: string,
  code_challenge: string,
  ios: boolean,
  android: boolean,
  completion: (authorizationCode: string) => void,
  onError: (error: Error | null) => void,
) => {
  try {
    const res = await axios.post(
      `/api/auth2/b2c/google`,
      {
        credential,
        sessionDeviceFingerprint: generateSimpleDeviceFingerprint(),
        scope: "b2c",
        redirect_uri: redirectUri,
        code_challenge: code_challenge,
        ios: ios ? true : undefined,
        android: android ? true : undefined,
      },
      { withCredentials: true },
    );

    if (res.data.authorization_code) {
      completion(res.data.authorization_code);
    } else {
      onError(null);
    }
  } catch (error) {
    await disconnectGoogle();
    onError(mapErrorFromException(error));
  }
};

export const signInWithApple = async (
  authCode: string,
  idToken: string,
  redirectUri: string,
  code_challenge: string,
  ios: boolean,
  android: boolean,
  completion: (authorizationCode: string) => void,
  onError: (error: Error | null) => void,
) => {
  try {
    const res = await axios.post(
      "/api/auth2/b2c/apple",
      {
        authCode,
        idToken,
        sessionDeviceFingerprint: generateSimpleDeviceFingerprint(),
        scope: "b2c",
        redirect_uri: redirectUri,
        code_challenge,
        ios: ios ? true : undefined,
        android: android ? true : undefined,
      },
      { withCredentials: true },
    );

    if (res.data.authorization_code) {
      completion(res.data.authorization_code);
    } else {
      onError(null);
    }
  } catch (error) {
    onError(mapErrorFromException(error));
  }
};

async function disconnectGoogle() {
  const w: any = window;
  if (
    w.gapi != null &&
    w.gapi.auth2 != null &&
    w.gapi.auth2.getAuthInstance() != null
  ) {
    const auth2: any = w.gapi.auth2.getAuthInstance();
    auth2.currentUser = null;
    auth2.signOut();
    auth2.disconnect();
  }
}

async function disconnectFB() {
  const w: any = window;
  if (w.FB) {
    await w.FB.logout();
  }
}

export const resetPassword = async (
  newPassword: string,
  resetCode: string,
  redirectUri: string,
  code_challenge: string,
  completion: (authorizationCode: string) => void,
  onError: (error: Error | null) => void,
) => {
  try {
    const res = await axios.post(
      "/api/auth2/b2c/resetPassword",
      {
        newPassword,
        resetCode,
        scope: "b2c",
        redirect_uri: redirectUri,
        code_challenge: code_challenge,
        sessionDeviceFingerprint: generateSimpleDeviceFingerprint(),
      },
      { withCredentials: true },
    );

    if (res.data.authorization_code) {
      completion(res.data.authorization_code);
    } else {
      onError(null);
    }
  } catch (error) {
    onError(mapErrorFromException(error));
  }
};

export const login = async (
  email: string,
  password: string,
  redirectUri: string,
  code_challenge: string,
  completion: (authorizationCode: string) => void,
  onError: (error: Error | null) => void,
) => {
  try {
    const res = await axios.post(
      "/api/auth2/b2c/login",
      {
        email,
        password,
        sessionDeviceFingerprint: generateSimpleDeviceFingerprint(),
        scope: "b2c",
        redirect_uri: redirectUri,
        code_challenge: code_challenge,
      },
      { withCredentials: true },
    );

    if (res.data.authorization_code) {
      completion(res.data.authorization_code);
    } else {
      onError(null);
    }
  } catch (error) {
    onError(mapErrorFromException(error));
  }
};

export const forgotPassword = async (
  email: string,
  redirect_uri: string,
  code_challenge: string,
  completion: () => void,
  onError: (error: Error | null) => void,
) => {
  try {
    const res = await axios.post(
      "/api/auth2/b2c/forgotPassword",
      { email, redirect_uri: redirect_uri, code_challenge: code_challenge },
      { withCredentials: true },
    );
    if (res.data.status === "ok") {
      completion();
    } else {
      onError(null);
    }
  } catch (error) {
    onError(mapErrorFromException(error));
  }
};

export const logout = async (
  redirectUri: string,
  code_challenge: string,
  completion: (authorizationCode: string) => void,
  onError: (error: Error | null) => void,
) => {
  await disconnectFB();
  await disconnectGoogle();

  try {
    const res = await axios.post(
      "/api/auth2/b2c/logout",
      {
        sessionDeviceFingerprint: generateSimpleDeviceFingerprint(),
        redirect_uri: redirectUri,
        code_challenge: code_challenge,
      },
      { withCredentials: true },
    );

    if (res.data.authorization_code) {
      completion(res.data.authorization_code);
    } else {
      onError(null);
    }
  } catch (error) {
    onError(mapErrorFromException(error));
  }
};

export const deactivate = async (
  redirectUri: string,
  code_challenge: string,
  completion: (authorizationCode: string) => void,
  onError: (error: Error | null) => void,
) => {
  await disconnectFB();
  await disconnectGoogle();

  try {
    const res = await axios.post(
      "/api/auth2/b2c/deactivate",
      {
        sessionDeviceFingerprint: generateSimpleDeviceFingerprint(),
        redirect_uri: redirectUri,
        code_challenge: code_challenge,
      },
      { withCredentials: true },
    );

    if (res.data.authorization_code) {
      completion(res.data.authorization_code);
    } else {
      onError(null);
    }
  } catch (error) {
    onError(mapErrorFromException(error));
  }
};

// generates a simple fingerprint consisting of some generic device infos - also uses the referrer and the hostname. See https://wiki.aschauerit.at/display/BA/Login+und+Registrierung
export function generateSimpleDeviceFingerprint() {
  const deviceString =
    window.navigator.platform +
    window.navigator.product +
    window.navigator.vendor;
  const referrer =
    window.document.referrer != null ? window.document.referrer : "local";
  const hostname =
    window.location.hostname != null ? window.location.hostname : "local";
  return uuidv5(
    `${deviceString}_${referrer}_${hostname}`,
    "10ba038e-48da-487b-96e8-8d3b99b6d18a",
  );
}
