import { BcThemeOptions } from "../themeBase";

export const ExtraProgramm: BcThemeOptions = {
  colors: {
    primary: "#7b9240",
    primaryLight: "#7b9240",
    primaryDark: "#7b9240",
    primaryContrast: "#fff",
    primaryLightContrast: "#fff",
    secondaryBackground: "#fff",
    bodyText: "#3C3C3B",
    background: "#fff",
  },
  styles: {
    headerStyle: "centerBanner",
    authPageStyle: "flat",
    logoStyle: "negative",
  },
  images: {
    landing: "/themes/extraprogrammLogo.jpeg",
    banner: "/themes/extraprogrammLogo.jpeg",
  },
  misc: {
    originUrl: "https://extraprogramm.com/",
    landingPageIconHeight: 300,
    topBarIconHeight: 80,
  },
};
