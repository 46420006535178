import * as React from "react";

import { SvgIcon } from "@material-ui/core";

interface Props {
  svg: any;
  color?: string;
  className?: any;
  style?: any;
  preserveAspectRatio?: string;
  onClick?: (clickEvent?: any) => void;
}

export function ColorSvg(props: Props) {
  const {
    svg: svgComponent,
    color,
    className,
    style,
    preserveAspectRatio,
    onClick,
  } = props;

  if (!svgComponent) {
    return <></>;
  }

  // should do this in the svgr template probably?
  // is fixed in MUIv5 via inheritViewBox prop
  // --> set prop & remove after transition
  const defaultSvgProps = svgComponent().props;
  let svgViewBox = defaultSvgProps?.viewBox;
  if (!svgViewBox && defaultSvgProps?.width && defaultSvgProps?.height) {
    svgViewBox = "0 0 " + defaultSvgProps.width + " " + defaultSvgProps.height;
  }

  return (
    <SvgIcon
      component={svgComponent}
      className={className}
      style={color ? { ...style, color } : style}
      viewBox={svgViewBox}
      onClick={onClick}
      preserveAspectRatio={preserveAspectRatio}
    />
  );
}
