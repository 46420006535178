import { RouteComponentProps } from "react-router";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { Progress } from "../components";
import { getParamValue } from "../utils/utils";
import { Error } from "../model/model";
import { deactivate } from "../actions/auth";
import { PageTracker } from "../utils/pageTracker";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "stretch",
      marginTop: "auto",
      marginBottom: "auto",
      flexGrow: 1,
      paddingTop: 40,
      paddingBottom: 40,
      paddingLeft: 20,
      paddingRight: 20,
    },
    topImage: {
      marginTop: 0,
      marginBottom: 30,
      maxWidth: 300,
    },
  });

export interface Props
  extends RouteComponentProps<void>,
    WithStyles<typeof styles> {
  theme: any;
}

class LogoutPage extends React.Component<Props> {
  componentDidMount() {
    const redirectUri = getParamValue("redirect_uri")!;
    const codeChallenge = getParamValue("code_challenge")!;
    deactivate(
      redirectUri,
      codeChallenge,
      (authorizationCode: string) => {
        if (redirectUri.indexOf("?") !== -1) {
          window.location.replace(
            `${redirectUri}&authorization_code=${authorizationCode}`,
          );
        } else {
          window.location.replace(
            `${redirectUri}?authorization_code=${authorizationCode}`,
          );
        }
      },
      (error: Error | null) => {},
    );
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <PageTracker />
        {this.props.theme!.landingPage.topImage && (
          <img
            src={this.props.theme!.landingPage.topImage}
            alt=""
            className={this.props.classes.topImage}
            style={{ marginBottom: 10 }}
          />
        )}

        <Progress marginTop={20} />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LogoutPage);
