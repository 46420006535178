import { Input, InputAdornment } from "@material-ui/core";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";

interface Props {
  id: string;
  error: boolean;
  placeholder?: string;
  onChange: (event: any) => void;
}

export default function PasswordInput(props: Props) {
  const { id, error, onChange, placeholder } = props;

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Input
      error={error}
      id={id}
      disableUnderline
      type={values.showPassword ? "text" : "password"}
      placeholder={placeholder}
      autoComplete="current-password"
      onChange={onChange}
      endAdornment={
        <InputAdornment position="end">
          <div
            onClick={() =>
              setValues({ ...values, showPassword: !values.showPassword })
            }
            onMouseDown={handleMouseDownPassword}>
            {values.showPassword ? (
              <VisibilityOff color={"secondary"} />
            ) : (
              <Visibility color={"secondary"} />
            )}
          </div>
        </InputAdornment>
      }
    />
  );
}
