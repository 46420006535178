import { BcThemeOptions } from "../themeBase";

export const BineLovesLife: BcThemeOptions = {
  colors: {
    primary: "#4e102e",
    primaryLight: "#fbe6dd",
    primaryDark: "#4e102e",
    primaryContrast: "#f2d3cb",
    primaryLightContrast: "#49172e",
    secondaryBackground: "#f2f2f2",
    bodyText: "#1a1c1e",
    indicatorProfile: "#f00",
    background: "#fff",
  },
  styles: {
    headerStyle: "bluecherries",
    authPageStyle: "default",
    logoStyle: "negative",
  },
  images: {
    banner: "/themes/bineLovesLife.png",
    landing: "/themes/bineLovesLife.png",
  },
  misc: {
    originUrl: "https://bineloveslife.com/",
    landingPageIconHeight: 300,
    topBarIconHeight: 116,
  },
};
