import * as React from "react";
import intl from "react-intl-universal";
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import AppStoreBadge from "../assets/images/appstoreBadge.svg";
import PlayStoreBadge from "../assets/images/playstoreBadge.svg";
import clsx from "clsx";
import { ColorSvg } from "./ColorSvg";
import FooterFacebook from "../assets/icons/FooterFacebook";
import FooterInstagram from "../assets/icons/FooterInstagram";
import { isRunningInMobileApp } from "../utils/utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.bottomBar.contrastText,
      minHeight: 300,
      backgroundColor: theme.bottomBar.backgroundColor,
      paddingLeft: 55,
      paddingRight: 55,
      paddingTop: 40,
      paddingBottom: 40 - 16,
    },
    section: {
      paddingBottom: 20,
      fontSize: theme.fontSizes.smallFont,
    },
    paragraph: {
      lineHeight: 1.2,
    },
    link: {
      color: theme.bottomBar.contrastText,
      textDecoration: "none",
      fontSize: theme.fontSizes.smallFont,
      marginBottom: 14,
      "&:hover": {
        textDecoration: "underline",
      },
    },
    socialIcon: {
      width: 40,
      height: 40,
    },
  }),
);

interface Props {
  className?: any;
}

export default function FooterRaw(props: Props) {
  const { className } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  const socialColor =
    theme.palette.primary.main === "#fff"
      ? theme.palette.primary.contrastText
      : theme.palette.primary.main;

  return (
    <Box className={clsx(classes.root, className)}>
      <Grid container direction={"row"}>
        <Grid item xs={7}>
          <Grid container direction={"column"}>
            {!isRunningInMobileApp() && (
              <Grid item className={classes.section}>
                {intl.get("footer.get_app")}
              </Grid>
            )}
            {!isRunningInMobileApp() && (
              <Grid item className={classes.section}>
                <a
                  href={
                    "https://apps.apple.com/us/app/family-cherries/id1626634876"
                  }
                  target={"_new"}>
                  <img
                    src={AppStoreBadge}
                    height={50}
                    style={{ marginRight: 10 }}
                  />
                </a>
                <a
                  href={
                    "https://play.google.com/store/apps/details?id=com.familycherries.app"
                  }
                  target={"_new"}>
                  <img src={PlayStoreBadge} height={50} />
                </a>
              </Grid>
            )}
            <Grid item className={classes.section} style={{ marginTop: 32 }}>
              <a
                href={"https://www.instagram.com/familycherriesapp/"}
                target="_blank"
                rel="noopener noreferrer">
                <ColorSvg
                  svg={FooterInstagram}
                  color={socialColor}
                  className={classes.socialIcon}
                />
              </a>
              <a
                href={"https://www.facebook.com/familycherriesapp/"}
                target="_blank"
                rel="noopener noreferrer">
                <ColorSvg
                  svg={FooterFacebook}
                  color={socialColor}
                  style={{ marginLeft: 10 }}
                  className={classes.socialIcon}
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {theme.externalPrivacyLink.length === 0 && (
            <p className={classes.paragraph}>
              <a
                href="https://family-cherries.com/datenschutz"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}>
                {intl.get("footer.privacy_policy_link")}
              </a>
            </p>
          )}
          {theme.externalPrivacyLink.length > 0 && (
            <>
              <p className={classes.paragraph}>
                <a
                  href="https://family-cherries.com/datenschutz"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}>
                  {intl.get("footer.privacy_policy_link")} family cherries
                </a>
              </p>
              <p className={classes.paragraph}>
                <a
                  href={theme.externalPrivacyLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}>
                  {intl.get("footer.privacy_policy_link")}{" "}
                  {theme.externalPartnerName}
                </a>
              </p>
            </>
          )}
          {theme.externalImprintLink.length === 0 && (
            <p className={classes.paragraph}>
              <a
                href="https://family-cherries.com/impressum"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}>
                {intl.get("footer.imprint_link")}
              </a>
            </p>
          )}
          {theme.externalImprintLink.length > 0 && (
            <>
              <p className={classes.paragraph}>
                <a
                  href="https://family-cherries.com/impressum"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}>
                  {intl.get("footer.imprint_link")} family cherries
                </a>
              </p>
              <p className={classes.paragraph}>
                <a
                  href={theme.externalImprintLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}>
                  {intl.get("footer.imprint_link")} {theme.externalPartnerName}
                </a>
              </p>
            </>
          )}

          <p className={classes.paragraph}>
            <a
              href="https://family-cherries.com/ueber_uns"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}>
              {intl.get("footer.about_link")}
            </a>
          </p>
        </Grid>
      </Grid>
    </Box>
  );
}
