import Grid from "@material-ui/core/Grid";
import { Divider, Typography, useTheme } from "@material-ui/core";
import * as React from "react";

interface Props {
  text: string;
  darkText?: boolean;
}

export default function TextDivider(props: Props) {
  const { text, darkText } = props;

  const theme = useTheme();

  return (
    <Grid container direction={"row"} alignItems={"center"}>
      <Grid item style={{ flexGrow: 1, paddingRight: 10 }}>
        <Divider />
      </Grid>
      <Grid item>
        <Typography
          variant={"body1"}
          align={"center"}
          color="secondary"
          style={{
            fontSize: theme.fontSizes.smallFont,
            color: darkText === true ? "#969695" : undefined,
          }}>
          {text}
        </Typography>
      </Grid>
      <Grid item style={{ flexGrow: 1, paddingLeft: 10 }}>
        <Divider />
      </Grid>
    </Grid>
  );
}
