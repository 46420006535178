import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const Facebook = props => (
  <SvgIcon width={46} height={46} {...props}>
    <path data-name="Rechteck 1458" fill="none" d="M15 15h16v16H15z" />
    <circle data-name="Ellipse 43" cx={23} cy={23} r={23} fill="#f6f6f6" />
    <path
      data-name="Pfad 1"
      d="M24.422 31v-7.289h2.489l.356-2.844h-2.845v-1.778c0-.8.267-1.422 1.422-1.422h1.511v-2.578c-.355 0-1.244-.089-2.222-.089a3.431 3.431 0 00-3.644 3.733v2.134H19v2.844h2.489V31z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Facebook;
