export const ThemeStyleOverrides = (themeProperties: any) => {
  return {
    overrides: overrides(themeProperties),
    props: defaultProps,
    typography: typography(themeProperties),
  };
};

const overrides = (themeProperties: any) => {
  return {
    MuiLink: {
      root: {
        color: themeProperties.linkButton,
      },
    },
    MuiCheckbox: {
      root: {
        color: themeProperties.palette.secondary.main,
        marginLeft: -10,
      },
      "&$checked": {
        color: themeProperties.palette.primary.main,
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: themeProperties.palette.secondary.light,
        },
      },
    },
    MuiInput: {
      root: {
        backgroundColor: themeProperties.palette.secondary.light,
        color: themeProperties.palette.secondary.dark,
        fontSize: themeProperties.fontSizes.mediumFont,
        borderRadius: 4,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 20,
        paddingRight: 20,
        minHeight: 40,
        margin: 0,
      },
      input: {
        "&::placeholder": {
          fontSize: themeProperties.fontSizes.smallFont,
          color: themeProperties.palette.secondary.main,
        },
      },
    },
    MuiButton: {
      root: {
        height: 50,
        padding: "0 30px",
        borderRadius: 4,
        fontSize: themeProperties.fontSizes.mediumFont,
      },
      sizeSmall: {
        height: 30,
        fontSize: themeProperties.fontSizes.smallFont,
        paddingLeft: 7,
        paddingRight: 7,
        paddingTop: 6,
        paddingBottom: 6,
      },
      contained: {
        backgroundColor: themeProperties.palette.primary.main,
        color: themeProperties.palette.primary.contrastText,
        "&:hover": {
          backgroundColor: themeProperties.palette.primary.dark,
          color: themeProperties.palette.primary.contrastText,
        },
        "&:disabled": {
          backgroundColor: themeProperties.palette.primary.light,
          color: themeProperties.palette.primary.contrastText,
        },
      },
      containedPrimary: {
        backgroundColor: themeProperties.palette.primary.main,
        color: themeProperties.palette.primary.contrastText,
        "&:hover": {
          backgroundColor: themeProperties.palette.primary.dark,
          color: themeProperties.palette.primary.contrastText,
        },
        "&:disabled": {
          backgroundColor: themeProperties.palette.primary.light,
          color: themeProperties.palette.primary.contrastText,
        },
      },
      containedSecondary: {
        backgroundColor: themeProperties.palette.secondary.main,
        color: themeProperties.palette.secondary.contrastText,
        "&:hover": {
          backgroundColor: themeProperties.palette.secondary.light,
          color: themeProperties.palette.secondary.contrastText,
        },
      },
      outlined: {
        backgroundColor: "white",
        color: themeProperties.palette.primary.main,
        border: "1px solid",
        "&:hover": {
          backgroundColor: "transparent",
          color: themeProperties.palette.primary.dark,
        },
      },
    },
  };
};

const typography = (themeProperties: any) => {
  return {
    fontSize: 16,
    fontFamily: "Manrope",
    button: {
      fontFamily: "Manrope",
      textTransform: "none",
      fontWeight: 400,
      fontSize: themeProperties.fontSizes.mediumFont,
    },
    h1: {
      fontSize: "2.5em", // 40px
      fontWeight: "bold",
    },
    h2: {
      fontSize: "1.875em", // 30px
      fontWeight: "bold",
    },
    h3: {
      fontSize: "1.375em", // 22px
      fontWeight: "bold",
    },
    h4: {
      fontSize: "1.125em", // 18px
      fontWeight: "bold",
    },
    h5: {
      fontSize: "1em", // 16px
      fontWeight: "bold",
    },
    h6: {
      fontSize: "0.875em", // 14px
      fontWeight: "bold",
    },
  };
};

const defaultProps = {
  MuiInput: {
    disableUnderline: true,
  },
  MuiButtonBase: {
    disableRipple: true,
  },
  MuiLink: {
    underline: "hover",
  },
  MuiCheckbox: {
    disableRipple: true,
  },
  MuiButton: {
    variant: "contained",
    disableElevation: true,
    color: "primary",
  },
};
