import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const FooterInstagram = props => (
  <SvgIcon width={40} height={40} {...props}>
    <g transform="translate(-237 -454)">
      <path data-name="Rechteck 1458" fill="none" d="M252 469h16v16h-16z" />
      <circle
        data-name="Ellipse 43"
        cx={20}
        cy={20}
        r={20}
        transform="translate(237 454)"
        fill="#fff"
      />
      <g data-name="Gruppe 108">
        <path
          data-name="Pfad 3"
          d="M257 467.422a24.53 24.53 0 013.2.089 4.123 4.123 0 011.511.267 3.122 3.122 0 011.511 1.511 4.123 4.123 0 01.267 1.511c0 .8.089 1.067.089 3.2a24.53 24.53 0 01-.089 3.2 4.123 4.123 0 01-.267 1.511 3.122 3.122 0 01-1.511 1.511 4.123 4.123 0 01-1.511.267c-.8 0-1.067.089-3.2.089a24.53 24.53 0 01-3.2-.089 4.123 4.123 0 01-1.511-.267 3.122 3.122 0 01-1.511-1.511 4.123 4.123 0 01-.267-1.511c0-.8-.089-1.067-.089-3.2a24.53 24.53 0 01.089-3.2 4.123 4.123 0 01.267-1.511 3.192 3.192 0 01.622-.889 1.5 1.5 0 01.889-.622 4.123 4.123 0 011.511-.267 24.53 24.53 0 013.2-.089m0-1.422a26.264 26.264 0 00-3.289.089 5.488 5.488 0 00-1.955.355 3.48 3.48 0 00-1.422.889 3.48 3.48 0 00-.89 1.423 4.05 4.05 0 00-.355 1.955A26.264 26.264 0 00249 474a26.264 26.264 0 00.089 3.289 5.488 5.488 0 00.356 1.956 3.48 3.48 0 00.889 1.422 3.48 3.48 0 001.422.889 5.488 5.488 0 001.956.356A26.264 26.264 0 00257 482a26.264 26.264 0 003.289-.089 5.488 5.488 0 001.956-.356 3.729 3.729 0 002.311-2.311 5.488 5.488 0 00.356-1.956c-.001-.888.088-1.155.088-3.288a26.264 26.264 0 00-.089-3.289 5.488 5.488 0 00-.356-1.956 3.48 3.48 0 00-.889-1.422 3.48 3.48 0 00-1.422-.889 5.488 5.488 0 00-1.955-.355A26.264 26.264 0 00257 466m0 3.911a4.023 4.023 0 00-4.089 4.089 4.089 4.089 0 104.089-4.089m0 6.756a2.619 2.619 0 01-2.667-2.667 2.619 2.619 0 012.667-2.667 2.619 2.619 0 012.667 2.667 2.619 2.619 0 01-2.667 2.667m4.267-7.911a.978.978 0 10.978.978.986.986 0 00-.978-.978"
          fill="currentColor"
          fillRule="evenodd"
        />
      </g>
    </g>
  </SvgIcon>
);

export default FooterInstagram;
