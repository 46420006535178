import { BcThemeOptions } from "../themeBase";
// FF6360

export const Familiii: BcThemeOptions = {
  colors: {
    primary: "#FF6360",
    primaryLight: "#FF6360",
    primaryDark: "#b73e7a",
    primaryContrast: "#fff",
    primaryLightContrast: "#fff",
    secondaryBackground: "#fff",
    indicatorProfile: "#b73e7a",
    indicatorFavorite: "#fff",
    bodyText: "#3C3C3B",
    background: "#fff",
  },
  styles: {
    headerStyle: "centerBanner",
    authPageStyle: "flat",
    logoStyle: "negative",
  },
  images: {
    landing: "/themes/familiii.jpg",
    banner: "/themes/familiii.jpg",
  },
  misc: {
    originUrl: "https://www.familiii.at",
    landingPageIconHeight: 100,
    topBarIconHeight: 70,
  },
};
