import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const Google = props => (
  <SvgIcon data-name="g+" width={46} height={46} {...props}>
    <path
      data-name="Pfad 150"
      d="M23 0A23 23 0 110 23 23 23 0 0123 0z"
      fill="#f6f6f6"
    />
    <path data-name="Rechteck 121" fill="none" d="M15 15h16v16H15z" />
    <path
      data-name="Pfad 51"
      d="M31 22.4v1.5h-1.5v1.5H28v-1.6h-1.5v-1.4H28v-1.5h1.5v1.5zm-10.9 0h4.8c0 .3.1.5.1.8a4.7 4.7 0 01-4.9 5 5.1 5.1 0 010-10.2 4.938 4.938 0 013.4 1.3l-1.4 1.4a2.666 2.666 0 00-2-.8 3.222 3.222 0 00-3.2 3.2 3.159 3.159 0 003.2 3.2 2.724 2.724 0 002.9-2.2h-2.9z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default Google;
