import { BcThemeOptions } from "../themeBase";

export const MamiConnection: BcThemeOptions = {
  colors: {
    primary: "#bfa279",
    primaryLight: "#bfa279",
    primaryDark: "#bfa279",
    primaryContrast: "#fff",
    primaryLightContrast: "#fff",
    secondaryBackground: "#f2f2f2",
    bodyText: "#3C3C3B",
    indicatorProfile: "#de5433",
    indicatorFavorite: "#de5433",
    background: "#fff",
  },
  styles: {
    headerStyle: "centerBanner",
    authPageStyle: "default",
    logoStyle: "negative",
  },
  images: {
    landing: "/themes/mamiConnectionLogo.png",
    banner: "/themes/mamiConnectionLogo.png",
  },
  misc: {
    originUrl: "https://www.mami-connection.de",
    landingPageIconHeight: 100,
    topBarIconHeight: 60,
  },
};
