import {
  createStyles,
  Grid,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import * as intl from "react-intl-universal";
import { LinkButton } from "../components/Buttons";
import { ErrorView } from "../components";
import { Helmet } from "react-helmet";
import { getParamValue } from "../utils/utils";
import { Error } from "../model/model";
import { signup } from "../actions/auth";
import AuthPageLayout from "../components/AuthPageLayout";
import TextDivider from "../components/TextDivider";
import { SocialLoginButtons } from "../components/SocialLoginButtons";
import AuthPageForm, { AuthPageFormState } from "../components/AuthPageForm";
import clsx from "clsx";
import { PageTracker } from "../utils/pageTracker";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginLeft: "auto",
      marginRight: "auto",
    },
    section: {
      paddingTop: "0px",
      paddingBottom: "12px",
    },
    login: {
      fontSize: theme.fontSizes.smallFont,
    },
    checkboxLabel: {
      fontSize: theme.fontSizes.smallFont,
    },
  });

export interface Props
  extends RouteComponentProps<void>,
    WithStyles<typeof styles> {}

export interface State {
  failed: boolean;
  progress: boolean;
  error: Error | null;
}

class SignupPage extends React.Component<Props, State> {
  state = {
    failed: false,
    progress: false,
    error: null,
  };

  handleChange = (name: any) => (event: any) => {
    if (this.state.failed) {
      this.setState({ failed: false, error: null, progress: false });
    }
    // @ts-ignore
    this.setState({
      [name]: event.target.value,
    });
  };

  signupPossible = (state: AuthPageFormState) => {
    if (!state) {
      return false;
    }
    if (
      state.hasAcceptedAGBs === false ||
      state.hasAcceptedPrivacyStatement === false
    ) {
      return false;
    }
    return state.email.length > 0 && state.password.length > 0;
  };

  signup = (state: AuthPageFormState) => {
    const redirectUri = getParamValue("redirect_uri")!;
    const codeChallenge = getParamValue("code_challenge")!;

    this.setState({ failed: true, error: null, progress: true });

    signup(
      state.email,
      state.password,
      state.birthdate?.getFullYear(),
      state.gender,
      redirectUri,
      codeChallenge,
      (authorizationCode: string) => {
        if (redirectUri.indexOf("?") !== -1) {
          window.location.replace(
            `${redirectUri}&authorization_code=${authorizationCode}`,
          );
        } else {
          window.location.replace(
            `${redirectUri}?authorization_code=${authorizationCode}`,
          );
        }
      },
      (error: Error | null) => {
        this.setState({ failed: true, error: error, progress: false });
      },
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={this.props.classes.root}>
        <PageTracker />
        <Helmet>
          <title>
            {intl.get(`seo.signup.title`) + " | " + intl.get(`app.name`)}
          </title>
        </Helmet>

        <AuthPageLayout
          title={intl.get("signup.title")}
          subTitle={intl.get("signup.subTitle")}
          onUseAsGuestClicked={() => {
            this.props.history.replace(
              `/autovalidate?redirect_uri=${getParamValue(
                "redirect_uri",
              )}&code_challenge=${getParamValue("code_challenge")}${
                getParamValue("theme") !== null
                  ? `&theme=${getParamValue("theme")}`
                  : ""
              }`,
            );
          }}>
          {this.state.failed && (
            <ErrorView
              error={this.state.error}
              defaultErrorMessage={intl.get("signup.error_user_already_exists")}
            />
          )}
          <Grid
            container
            alignItems="stretch"
            justifyContent="center"
            direction="column">
            <Grid item>
              <AuthPageForm
                buttonLabel={intl.get("signup.button")}
                buttonEnabled={this.signupPossible}
                buttonProgress={this.state.progress}
                onButtonClick={this.signup}
                variant={"register"}
              />
            </Grid>

            <Grid
              item
              container
              className={clsx(classes.section, classes.login)}
              justifyContent={"center"}>
              {intl.get("signup.account_already")}&nbsp;
              <LinkButton
                title={intl.get("signup.jump_to_login")}
                to={`/login`}
                search={this.props.location.search}
                style={{ fontSize: "inherit" }}
              />
            </Grid>
          </Grid>

          <Grid item style={{ paddingTop: 26 - 12, paddingBottom: 26 }}>
            <TextDivider text={intl.get("or")} darkText={true} />
          </Grid>

          <Grid item>
            <SocialLoginButtons
              onError={(error: any) => {
                this.setState({
                  ...this.state,
                  failed: true,
                  error: error,
                  progress: false,
                });
              }}
            />
          </Grid>
        </AuthPageLayout>
      </div>
    );
  }
}

export default withStyles(styles)(SignupPage);
