import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const FooterFacebook = props => (
  <SvgIcon width={40} height={40} {...props}>
    <g transform="translate(-237 -454)">
      <path data-name="Rechteck 1458" fill="none" d="M252 469h16v16h-16z" />
      <circle
        data-name="Ellipse 43"
        cx={20}
        cy={20}
        r={20}
        transform="translate(237 454)"
        fill="#fff"
      />
      <path
        data-name="Pfad 1"
        d="M258.422 482v-7.289h2.489l.356-2.844h-2.845v-1.778c0-.8.267-1.422 1.422-1.422h1.511v-2.578c-.355 0-1.244-.089-2.222-.089a3.431 3.431 0 00-3.644 3.733v2.134H253v2.844h2.489V482z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </g>
  </SvgIcon>
);

export default FooterFacebook;
