import {
  createStyles,
  Grid,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core";
import * as React from "react";
import { useAppTheme } from "../hooks/useAppTheme";
import { ColorSvg } from "./ColorSvg";
import FamilyCherriesLogoNegativ from "../assets/icons/FamilyCherriesLogoNegativ";
import { useHistory } from "react-router";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topBarHeight: {
      height: `calc(116px + env(safe-area-inset-top) / 2)`,
      [theme.breakpoints.down("md")]: {
        height: `calc(73px + env(safe-area-inset-top) / 2)`,
      },
    },
    topBarHeightSmall: {
      height: `calc(48px + env(safe-area-inset-top) / 2)`,
    },
    top: {
      paddingTop: `env(safe-area-inset-top)`,
      backgroundColor: theme.topBar.backgroundColor,
      paddingLeft: 55,
      paddingRight: 55,
      marginBottom: theme.toolbarStyle === "elevated" ? 15 : 0,
      boxShadow:
        theme.toolbarStyle === "elevated"
          ? "0px 5px 8px 0 rgb(22 22 22 / 8%)"
          : undefined,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
      "&.small": {
        paddingLeft: 5,
        paddingRight: 5,
      },
    },
    link: {
      display: "none",
    },
    logoContainer: {
      display: "flex",
      flexDirection: "row",
      zIndex: 5,
      cursor: "pointer",
      flexGrow: 1,
    },
    topIconSvg: {
      height: "100%",
      flexGrow: 1,
      paddingTop: 8,
      paddingBottom: 8,
      [theme.breakpoints.down("md")]: {
        maxHeight: 40,
      },
      maxHeight: 56,
      marginTop: "auto",
      marginBottom: "auto",
    },
    topIconPng: {
      height: "100%",
      paddingTop: 8,
      paddingBottom: 8,
      [theme.breakpoints.down("md")]: {
        maxHeight: 40,
      },
      maxHeight: 56,
      marginTop: "auto",
      marginBottom: "auto",
    },
    hidden: {
      visibility: "hidden",
    },
    growingContainer: {
      flexGrow: 1,
      flexShrink: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    nonGrowingContainer: {
      flexGrow: 0,
      flexShrink: 1,
    },
    absoluteCenteredContainer: {
      position: "absolute",
      left: "50%",
      transform: "translate(-50%, 0)",
    },
    flexCenter: {
      alignItems: "center",
      display: "flex",
    },
    profileBadge: {
      height: 8,
      minWidth: 8,
    },
    actionIcon: {
      width: 24,
      height: 24,
    },
  }),
);

interface Props {
  size: "small" | "default";
}

export default function TopBar(props: Props) {
  const { size } = props;

  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();

  const heightClass =
    size === "small" ? classes.topBarHeightSmall : classes.topBarHeight;

  return (
    <Grid
      className={clsx(classes.top, heightClass, size)}
      container
      justifyContent="space-between">
      <Grid
        item
        className={clsx(classes.logoContainer)}
        onClick={() => {
          history.push("/" + history.location.search);
        }}>
        <TopBarLogo
          classes={classes}
          type={theme.logoStyle}
          color={theme.topBar.contrastText}
        />
      </Grid>
    </Grid>
  );
}

interface LogoProps {
  type: "negative" | "default";
  color?: string;
  classes: any;
}

function TopBarLogo(props: LogoProps) {
  const { classes, type, color } = props;

  if (type === "negative") {
    return (
      <ColorSvg
        svg={FamilyCherriesLogoNegativ}
        className={classes.topIconSvg}
        color={color}
        preserveAspectRatio={"xMinYMin meet"}
      />
    );
  } else {
    return (
      <img src={"/family-cherries-logo.png"} className={classes.topIconPng} />
    );
  }
}
