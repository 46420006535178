import { Button, CircularProgress } from "@material-ui/core";
import React from "react";

interface Props {
  loading: boolean;
  onClick: () => void;
  buttonLabel: string;
  fullWidth?: boolean;
  disabled?: boolean;
}

export default function LoadingButton(props: Props) {
  const { loading, buttonLabel, onClick, fullWidth, disabled } = props;

  return (
    <div style={{ display: "grid" }}>
      <Button
        style={{ gridRow: 1, gridColumn: 1 }}
        fullWidth={fullWidth}
        onClick={onClick}
        disabled={disabled || loading}>
        {loading && (
          <CircularProgress
            size={20}
            style={{
              gridColumn: 1,
              gridRow: 1,
              zIndex: 1,
              marginRight: 16,
              marginLeft: -16,
            }}
          />
        )}
        {buttonLabel}
      </Button>
    </div>
  );
}
