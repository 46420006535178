export function mapErrorFromException(exception: any): Error {
  let statusCode = null;
  if (exception.response) {
    statusCode = exception.response.status;
  }
  let errorCode = null;
  if (exception.response && exception.response.data) {
    errorCode = exception.response.data.error_code;
  }
  let serverError = null;
  if (exception.response && exception.response.data) {
    serverError = exception.response.data.error;
  }

  return {
    error: serverError,
    statusCode,
    error_code: errorCode,
    response: exception.response,
  };
}

export interface Error {
  error: string | any;
  statusCode?: Number | null;
  response?: any | null;
  error_code?: string | null;
}

export enum Mode {
  AUTO = "auto",
  LOGIN = "login",
  SIGNUP = "signup",
  FORGOT_PASSWORD = "forgot_password",
  LOGOUT = "logout",
  RESET_PASSWORD = "resetPassword",
  DEACTIVATE = "deactivate",
}
