import { BcThemeOptions } from "../themeBase";

export const DieDanners: BcThemeOptions = {
  colors: {
    primary: "#2f667d",
    primaryLight: "#2f667d",
    primaryDark: "#2f667d",
    primaryContrast: "#80d5d9",
    primaryLightContrast: "#fff",
    secondaryBackground: "#f2f2f2",
    bodyText: "#171616",
    background: "#fff",
  },
  styles: {
    headerStyle: "centerBanner",
    authPageStyle: "default",
    logoStyle: "negative",
  },
  images: {
    banner: "/themes/dieDannersBanner.jpg",
    landing: "/themes/dieDannersBanner.jpg",
  },
  misc: {
    originUrl: "http://diedanners.com",
    topBarIconHeight: 90,
    landingPageIconHeight: 80,
  },
};
