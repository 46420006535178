import { BcThemeOptions } from "../themeBase";

export const BabyExpress: BcThemeOptions = {
  colors: {
    primary: "#FC0B9D",
    primaryLight: "#eac4cc",
    primaryDark: "#b5257b",
    primaryContrast: "#fff",
    primaryLightContrast: "#fff",
    bodyText: "#111111",
    secondaryBackground: "#f2f2f2",
    background: "#fff",
    indicatorProfile: "#0f0",
    indicatorFavorite: "#fff",
  },
  styles: {
    headerStyle: "centerBanner",
    authPageStyle: "default",
    logoStyle: "negative",
  },
  images: {
    banner: "/themes/babyexpress.png",
    landing: "/themes/babyexpress.png",
  },
  misc: {
    originUrl: "https://www.babyexpress.at",
    landingPageIconHeight: 80,
    topBarIconHeight: 56,
  },
};
