import { BcThemeOptions } from "../themeBase";

export const DieKleineBotin: BcThemeOptions = {
  colors: {
    primary: "#a1cfcb",
    primaryLight: "#a1cfcb",
    primaryDark: "#88c2bd",
    primaryContrast: "#fff",
    primaryLightContrast: "#fff",
    secondaryBackground: "#fff",
    bodyText: "#3C3C3B",
    indicatorProfile: "#eabfd3",
    background: "#fff",
  },
  styles: {
    headerStyle: "centerBanner",
    authPageStyle: "flat",
    logoStyle: "negative",
  },
  images: {
    landing: "/themes/diekleineBotinLogo.jpeg",
    banner: "/themes/diekleineBotinLogo.jpeg",
  },
  misc: {
    originUrl: "https://diekleinebotin.at",
    topBarIconHeight: 90,
    landingPageIconHeight: 80,
  },
};
