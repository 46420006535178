import {
  createStyles,
  Grid,
  TextField,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import * as intl from "react-intl-universal";
import { isMobile } from "react-device-detect";
import { ErrorView, SectionLabel } from "../components";
import { DefaultButton } from "../components/Buttons";
import { Helmet } from "react-helmet";
import { getParamValue } from "../utils/utils";
import { Error } from "../model/model";
import { resetPassword } from "../actions/auth";
import { PageTracker } from "../utils/pageTracker";
import StringUtils from "../utils/StringUtils";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 640,
      paddingTop: 20,
      paddingRight: 10,
      paddingLeft: 10,
      marginLeft: "auto",
      marginRight: "auto",
    },
    title: {
      fontWeight: "bold",
      fontSize: theme.fontSizes.veryLargeFont,
    },
    subTitle: {
      fontWeight: "bold",
      fontSize: theme.fontSizes.largeFont,
      marginBottom: 10,
    },
    section: {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    textField: {
      margin: 0,
    },
    button: {
      paddingTop: "10px",
      paddingBottom: "10px",
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      alignItems: "flex-start",
      [theme.breakpoints.down("xs")]: {
        alignItems: "stretch",
      },
    },
  });

export interface Props
  extends RouteComponentProps<void>,
    WithStyles<typeof styles> {}

export interface State {
  password: string;
  passwordRepeat: string;
  failed: boolean;
  error: Error | null;
}

class ResetPasswordPage extends React.Component<Props, State> {
  state = {
    password: "",
    passwordRepeat: "",
    failed: false,
    error: null,
  };

  handleChange = (name: string) => (event: any) => {
    let newState = { ...this.state, [name]: event.target.value };
    if (this.state.failed) {
      newState = { ...newState, failed: false, error: null };
    }
    this.setState(newState);
  };

  resetPassword = () => {
    const redirectUri = getParamValue("redirect_uri")!;
    const codeChallenge = getParamValue("code_challenge")!;
    const resetCode = getParamValue("confirmation_code")!;

    resetPassword(
      this.state.password,
      resetCode,
      redirectUri,
      codeChallenge,
      (authorizationCode: string) => {
        if (redirectUri.indexOf("?") !== -1) {
          window.location.replace(
            `${redirectUri}&authorization_code=${authorizationCode}`,
          );
        } else {
          window.location.replace(
            `${redirectUri}?authorization_code=${authorizationCode}`,
          );
        }
      },
      (error: Error | null) => {
        this.setState({ ...this.state, failed: true, error: error });
      },
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <PageTracker />
        <Helmet>
          <title>
            {intl.get(`seo.resetpassword.title`) + " | " + intl.get(`app.name`)}
          </title>
        </Helmet>

        <div className={classes.title}>{intl.get("resetPassword.title")}</div>
        <div className={classes.subTitle}>
          {intl.get("resetPassword.subTitle")}
        </div>
        {this.state.failed && (
          <ErrorView
            error={this.state.error}
            defaultErrorMessage={intl.get("error.generic")}
          />
        )}

        <Grid container justifyContent="center" direction="column">
          <Grid item className={classes.section} container direction="row">
            <Grid container spacing={isMobile ? 0 : 3}>
              <Grid item xs={12} sm={6}>
                <Grid
                  item
                  container
                  className={classes.section}
                  direction="column">
                  <SectionLabel>
                    {intl.get("resetPassword.password")}
                  </SectionLabel>
                  <TextField
                    id="password-input"
                    type="password"
                    autoComplete="current-password"
                    onChange={this.handleChange("password")}
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid
                  item
                  container
                  className={classes.section}
                  direction="column">
                  <SectionLabel>
                    {intl.get("resetPassword.password_repeat")}
                  </SectionLabel>
                  <TextField
                    id="password-repeat-input"
                    type="password"
                    autoComplete="current-password"
                    onChange={this.handleChange("passwordRepeat")}
                    margin="normal"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <div>
              <DefaultButton
                onClick={this.resetPassword}
                title={intl.get("resetPassword.button")}
                disabled={!this.resetPossible()}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  resetPossible = () => {
    return (
      !StringUtils.isNullOrEmpty(this.state.password) &&
      !StringUtils.isNullOrEmpty(this.state.passwordRepeat) &&
      this.state.passwordRepeat === this.state.password
    );
  };
}

export default withStyles(styles)(ResetPasswordPage);
