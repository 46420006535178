import { BcThemeOptions } from "../themeBase";

export const BlueCherries: BcThemeOptions = {
  colors: {
    primary: "#6E99FF",
    primaryLight: "#B6CCFF",
    primaryLightContrast: "#3c3c3b",
    primaryDark: "#316bcb",
    primaryContrast: "#fff",
    browserThemeColor: "#5778d8",
    bodyText: "#3C3C3B",
    indicatorProfile: "#FFCEE0",
    indicatorFavorite: "#FFCEE0",
    topBarBackground: "#f2f2f2",
    topBarContrast: "#6E99FF",
    secondaryBackground: "#f2f2f2",
    background: "#fff",
  },
  styles: {
    headerStyle: "bluecherries",
    authPageStyle: "default",
    logoStyle: "default",
  },
  images: {
    banner: "",
    landing: "/family-cherries-logo.png",
  },
  misc: {
    originUrl: "",
    landingPageIconHeight: 80,
    topBarIconHeight: 56,
  },
};
