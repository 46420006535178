import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import ToolbarBackIcon from "../assets/icons/toolbar_back.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100vw",
      paddingRight: 15,
      display: "flex",
      alignItems: "center",
      paddingLeft: 15,
      minHeight: 73,
    },
    title: {
      marginTop: "auto",
      marginBottom: "auto",
      marginLeft: 20,
    },
    backIcon: {
      height: 14.5,
      width: 14.5,
    },
  }),
);

interface Props {
  title: string;
  onBackClicked: () => void;
}

export default function MobileToolbar(props: Props) {
  const { title, onBackClicked } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.root}>
      <IconButton onClick={onBackClicked}>
        <img src={ToolbarBackIcon} className={classes.backIcon} />
      </IconButton>
      <Typography variant={"h3"} className={classes.title}>
        {title}
      </Typography>
    </div>
  );
}
