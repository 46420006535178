import { createStyles, Theme, withStyles } from "@material-ui/core";
import * as React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme: Theme) =>
  createStyles({
    progressContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  });

const aProgress = function (props: { marginTop?: number; classes: any }) {
  const { classes } = props;
  const marginTop = props.marginTop ? props.marginTop : 0;
  return (
    <div className={classes.progressContainer} style={{ marginTop }}>
      <CircularProgress />
    </div>
  );
};

export default withStyles(styles)(aProgress);
export const Progress = withStyles(styles)(aProgress);
