import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

const Apple = props => (
  <SvgIcon width={46} height={46} {...props}>
    <defs>
      <clipPath id="a">
        <path data-name="Rechteck 1507" fill="#6e99ff" d="M0 0h13.044v16H0z" />
      </clipPath>
    </defs>
    <circle data-name="Ellipse 42" cx={23} cy={23} r={23} fill="#f6f6f6" />
    <g data-name="apple logo">
      <g
        data-name="Gruppe 80"
        clipPath="url(#a)"
        fill="#currentColor"
        fillRule="evenodd"
        transform="translate(16 15)">
        <path
          data-name="Pfad 144"
          d="M7.197 1.392A3.425 3.425 0 019.706 0a3.417 3.417 0 01-.827 2.558 2.7 2.7 0 01-2.383 1.121 2.971 2.971 0 01.7-2.286"
        />
        <path
          data-name="Pfad 145"
          d="M6.634 4.59c.541 0 1.545-.743 2.851-.743a3.571 3.571 0 013.134 1.6 3.474 3.474 0 00-1.73 3.031 3.563 3.563 0 002.156 3.256s-1.507 4.241-3.542 4.241c-.935 0-1.662-.63-2.647-.63-1 0-2 .653-2.649.653C2.348 15.998 0 11.975 0 8.741 0 5.559 1.987 3.89 3.852 3.89c1.212 0 2.152.7 2.782.7"
        />
      </g>
    </g>
  </SvgIcon>
);

export default Apple;
