import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core";
import * as React from "react";
import { useAppTheme } from "../hooks/useAppTheme";
import TopBar from "./TopBar";
import TopBanner from "./TopBanner";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  }),
);

export default function Header() {
  const classes = useStyles();
  const appTheme = useAppTheme();

  return (
    <div className={classes.root}>
      <TopBar size={appTheme.is3rdParty ? "small" : "default"} />
      {appTheme.is3rdParty && <TopBanner />}
    </div>
  );
}
