import { RouteComponentProps } from "react-router";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { Progress } from "../components";
import { getParamValue } from "../utils/utils";
import { Mode } from "../model/model";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "stretch",
      marginTop: "auto",
      marginBottom: "auto",
      flexGrow: 1,
      paddingTop: 40,
      paddingBottom: 40,
      paddingLeft: 20,
      paddingRight: 20,
    },
    topImage: {
      marginTop: 0,
      marginBottom: 40,
      maxWidth: 300,
    },
  });

export interface Props
  extends RouteComponentProps<void>,
    WithStyles<typeof styles> {
  theme: any;
}

class LandingPage extends React.Component<Props> {
  componentDidMount() {
    const mode = getParamValue("mode");
    const redirectUri = getParamValue("redirect_uri");
    const codeChallenge = getParamValue("code_challenge");

    if (mode === null || redirectUri === null || codeChallenge === null) {
      window.location.replace("https://family-cherries.com/events");
    } else if (mode === Mode.AUTO) {
      this.props.history.replace({
        pathname: "/autovalidate",
        search: this.props.location.search,
      });
    } else if (mode === Mode.LOGIN) {
      this.props.history.replace({
        pathname: "/login",
        search: this.props.location.search,
      });
    } else if (mode === Mode.SIGNUP) {
      this.props.history.replace({
        pathname: "/signup",
        search: this.props.location.search,
      });
    } else if (mode === Mode.LOGOUT) {
      this.props.history.replace({
        pathname: "/logout",
        search: this.props.location.search,
      });
    } else if (mode === Mode.DEACTIVATE) {
      this.props.history.replace({
        pathname: "/deactivate",
        search: this.props.location.search,
      });
    } else if (mode === Mode.FORGOT_PASSWORD) {
      this.props.history.replace({
        pathname: "/forgotPassword",
        search: this.props.location.search,
      });
    } else if (mode === Mode.RESET_PASSWORD) {
      this.props.history.replace({
        pathname: "/resetPassword",
        search: this.props.location.search,
      });
    } else {
      this.props.history.replace({
        pathname: "/autovalidate",
        search: this.props.location.search,
      });
    }
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        {this.props.theme!.landingPage.topImage && (
          <img
            src={this.props.theme!.landingPage.topImage}
            alt=""
            className={this.props.classes.topImage}
          />
        )}

        <Progress marginTop={20} />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LandingPage);
