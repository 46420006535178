import { useEffect } from "react";
import { useMatomo } from "@jonkoops/matomo-tracker-react";

export const PageTracker = ({}) => {
  const { trackPageView } = useMatomo();
  useEffect(() => {
    trackPageView();
  }, []);
  return null;
};
