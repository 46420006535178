import Grid from "@material-ui/core/Grid";
import * as React from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import TitleDecor from "../assets/images/login-title-decor.svg";
import intl from "react-intl-universal";
import { isMobile } from "react-device-detect";
import MobileToolbar from "./MobileToolbar";
import DecoratedTypography from "./DecoratedTypography";

interface Props {
  title: string;
  subTitle: string;
  onUseAsGuestClicked?: () => void;
  children: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1366,
    },
    contentSection: {
      paddingLeft: 35,
      paddingRight: 35,
      paddingBottom: 65,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 30,
        paddingRight: 30,
      },
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    titleSection: {
      paddingTop: 50,
      paddingLeft: 55,
      paddingRight: 35,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 36,
      },
    },
    title: {},
    subTitle: {
      fontWeight: "normal",
      paddingRight: 80,
      [theme.breakpoints.down("xs")]: {
        paddingRight: 6,
        fontSize: theme.fontSizes.mediumFont,
      },
    },
    useAsGuest: {
      marginTop: 60,
      fontSize: theme.fontSizes.smallFont,
      cursor: "pointer",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    actionContainer: {
      marginTop: 30,
      [theme.breakpoints.down("xs")]: {
        marginTop: 25,
        display: "flex",
      },
    },
    registerButton: {
      minWidth: 168,
      marginRight: 16,
      [theme.breakpoints.down("xs")]: {
        minWidth: 165,
        marginRight: 15,
        flex: 1,
      },
    },
    loginButton: {
      minWidth: 168,
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      [theme.breakpoints.down("xs")]: {
        minWidth: 165,
        flex: 1,
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),
);

export default function AuthPageLayout(props: Props) {
  const { title, subTitle, onUseAsGuestClicked, children } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid
      container
      direction={"row"}
      justifyContent={"center"}
      className={classes.root}>
      {!isMobile && (
        <Grid item xs={12} md={6} className={classes.titleSection}>
          <Grid container direction={"column"}>
            <Grid item>
              <DecoratedTypography
                text={title}
                typographyVariant={"h2"}
                className={classes.title}
              />
            </Grid>
            <Grid item>
              <Typography variant={"h3"} className={classes.subTitle}>
                {subTitle}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}

      {isMobile && (
        <MobileToolbar
          title={title}
          onBackClicked={() => onUseAsGuestClicked()}
        />
      )}

      <Grid
        item
        container
        xs={12}
        md={6}
        className={classes.contentSection}
        direction={"column"}>
        {!isMobile && (
          <Grid item style={{ minHeight: 65 }}>
            {onUseAsGuestClicked && (
              <Box
                className={classes.useAsGuest}
                display="flex"
                onClick={() => onUseAsGuestClicked()}
                justifyContent="flex-end">
                {intl.get("login.use_as_guest")}
              </Box>
            )}
          </Grid>
        )}
        <Grid item>{children}</Grid>
      </Grid>
    </Grid>
  );
}
