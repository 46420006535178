import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core";
import * as React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bannerImage: {
      maxHeight: theme.topBar.iconHeight,
    },
    bannerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 10,
      paddingBottom: 2,
      background: theme.carousel.heroBackground,
    },
  }),
);

interface Props {}

export default function TopBanner(props: Props) {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.bannerContainer}>
      <a href={theme.originUrl}>
        <img src={theme.topBar.icon} alt="" className={classes.bannerImage} />
      </a>
    </div>
  );
}
