import * as React from "react";
import { createStyles, FormLabel, Theme, withStyles } from "@material-ui/core";

const styles = (theme: Theme) =>
  createStyles({
    sectionHeaderLabel: {
      color: theme.sectionHeader.color,
      fontSize: theme.fontSizes.verySmallFont,
      marginBottom: 6,
      fontFamily: theme.headerFontFamily,
    },
  });

const aSectionLabel = function (props: { classes?: any; children?: any }) {
  const { classes } = props;
  return (
    <FormLabel className={classes.sectionHeaderLabel}>
      {props.children}
    </FormLabel>
  );
};

export default withStyles(styles)(aSectionLabel);
export const SectionLabel = withStyles(styles)(aSectionLabel);
