import { BcThemeOptions } from "../themeBase";

export const Mamaleben: BcThemeOptions = {
  colors: {
    primary: "#464646",
    primaryLight: "#e0dbdb",
    primaryLightContrast: "#464646",
    primaryDark: "#383838",
    primaryContrast: "#FFFFFF",
    browserThemeColor: "#5778d8",
    bodyText: "#030303",
    indicatorProfile: "#38D7C6",
    indicatorFavorite: "#38D7C6",
    topBarBackground: "#ffffff",
    topBarContrast: "#464646",
    secondaryBackground: "#f2f2f2",
    background: "#fff",
  },
  styles: {
    headerStyle: "bluecherries",
    authPageStyle: "default",
    logoStyle: "negative",
  },
  images: {
    banner: "/themes/mamaleben.png",
    landing: "/themes/mamaleben.png",
  },
  misc: {
    originUrl: "https://www.mamaleben.de",
    landingPageIconHeight: 100,
    topBarIconHeight: 100,
    externalPrivacyLink: "https://www.mamaleben.de/datenschutz/",
    externalImprintLink: "https://www.mamaleben.de/kontakt/",
    externalPartnerName: "Mamaleben",
  },
};
