import { BcThemeOptions } from "../themeBase";

export const StillesBunt: BcThemeOptions = {
  colors: {
    primary: "#CDB79E",
    primaryLight: "#CDB79E",
    primaryDark: "#CDB79E",
    primaryContrast: "#242424",
    primaryLightContrast: "#fff",
    secondaryBackground: "#fff",
    bodyText: "#242424",
    indicatorProfile: "#55b4c9",
    background: "#fff",
    heroBackground: "rgba(255,255,255,.9)",
    heroText: "#242424",
  },
  styles: {
    headerStyle: "centerBanner",
    authPageStyle: "flat",
    toolbarStyle: "elevated",
    logoStyle: "negative",
  },
  images: {
    banner: "/themes/stillesbunt.jpg",
    landing: "/themes/stillesbunt.jpg",
  },
  misc: {
    originUrl: "http://www.stillesbunt.at",
    topBarIconHeight: 70,
    landingPageIconHeight: 80,
  },
};
