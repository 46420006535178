import { BcThemeOptions } from "../themeBase";

export const GluckeUndSo: BcThemeOptions = {
  colors: {
    primary: "#dcc5ab",
    primaryLight: "#dcc5ab",
    primaryDark: "#dcc5ab",
    primaryContrast: "#fff",
    primaryLightContrast: "#fff",
    secondaryBackground: "#fff",
    bodyText: "#3C3C3B",
    background: "#fff",
    indicatorProfile: "#d951c9",
    indicatorFavorite: "#fff",
  },
  styles: {
    headerStyle: "centerBanner",
    authPageStyle: "flat",
    logoStyle: "negative",
  },
  images: {
    landing: "/themes/gluckeundso.jpg",
    banner: "/themes/gluckeundso.jpg",
  },
  misc: {
    originUrl: "https://gluckeundso.de",
    landingPageIconHeight: 200,
    topBarIconHeight: 100,
  },
};
