import * as React from "react";
import { CSSProperties } from "react";
import { Button, createStyles, Theme, withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const styles = (theme: Theme) =>
  createStyles({
    linkButton: {
      fontWeight: "normal",
      fontSize: theme.fontSizes.mediumFont,
      textTransform: "none",
      color: theme.linkButton,
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline",
      },
    },
    linkButtonSecondary: {
      fontWeight: "normal",
      fontSize: theme.fontSizes.mediumFont,
      textTransform: "none",
      paddingLeft: 5,
      paddingRight: 5,
      color: theme.palette.secondary.main,
      textDecoration: "underline",
      "&:hover": {
        color: theme.palette.secondary.dark,
      },
    },
    defaultButton: {
      boxShadow: "none",
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      height: 50,
      padding: "0 30px",
      borderRadius: 4,
      "&:hover": {
        background: theme.palette.primary.dark,
        boxShadow: "none",
      },
      "&:disabled": {
        background: theme.palette.primary.light,
        boxShadow: "none",
      },
    },
    defaultButtonLabel: {
      textTransform: "none",
      color: theme.palette.primary.contrastText,
      fontWeight: 600,
      fontSize: theme.fontSizes.mediumFont,
    },
    defaultButtonLabelBold: {
      textTransform: "none",
      color: theme.palette.primary.contrastText,
      fontWeight: 900,
      fontSize: theme.fontSizes.mediumFont,
    },
    defaultButtonOutline: {
      boxShadow: "none",
      background: "white",
      color: theme.palette.primary.main,
      height: 50,
      padding: "0 30px",
      borderRadius: 4,
      border: "1px solid",
      "&:hover": {
        background: theme.palette.primary.main,
        boxShadow: "none",
        color: "white",
      },
    },
    defaultButtonLabelOutline: {
      textTransform: "none",
      color: theme.palette.primary.main,
      fontWeight: 600,
      fontSize: theme.fontSizes.mediumFont,
      "&:hover": {
        color: "white",
      },
    },
  });

const aDefaultButton = function (props: any) {
  if (props.outlineStyle) {
    const copiedProps = { ...props };
    delete copiedProps.outlineStyle;
    return (
      <Button
        {...copiedProps}
        onClick={props.onClick}
        classes={{
          root: props.classes.defaultButtonOutline,
          label: props.classes.defaultButtonLabelOutline,
        }}>
        {props.title}
      </Button>
    );
  }
  const labelStyle =
    props.bold === true
      ? props.classes.defaultButtonLabelBold
      : props.classes.defaultButtonLabel;
  const copiedProps = { ...props };
  delete copiedProps.bold;
  return (
    <Button
      {...copiedProps}
      onClick={props.onClick}
      classes={{
        root: props.classes.defaultButton,
        label: labelStyle,
      }}>
      {props.title}
    </Button>
  );
};

const aLinkButton = function (props: {
  classes: any;
  title: string;
  to?: string;
  search: string;
  onClick?: any;
  secondary?: boolean;
  style?: any;
}) {
  const { classes } = props;
  const className = props.secondary
    ? classes.linkButtonSecondary
    : classes.linkButton;
  const style: CSSProperties = props.style != null ? props.style : {};
  if (props.to) {
    return (
      <Link
        to={{ pathname: props.to!, search: props.search }}
        className={className}
        style={style}>
        {props.title}
      </Link>
    );
  }
  return (
    <Button onClick={props.onClick} className={className}>
      {props.title}
    </Button>
  );
};

export const LinkButton = withStyles(styles)(aLinkButton);
export const DefaultButton = withStyles(styles)(aDefaultButton);
