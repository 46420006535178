import { useLocation } from "react-router";
import * as BCTheme from "../theme";

export function useAppTheme() {
  const { search } = useLocation();

  const theme = new URLSearchParams(search).get("theme");
  const is3rdParty = theme && BCTheme.themes[theme];

  return {
    theme,
    is3rdParty,
  };
}
