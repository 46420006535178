import { createStyles, Theme, withStyles } from "@material-ui/core";
import * as React from "react";
import * as intl from "react-intl-universal";
import { Error } from "../model/model";

const styles = (theme: Theme) =>
  createStyles({
    error: {
      color: "white",
      marginTop: 1,
      marginBottom: 10,
      background: "rgb(229, 114, 102)",
      borderRadius: 4,
      fontSize: theme.fontSizes.smallFont,
      padding: 5,
      fontFamily: theme.fontFamily,
    },
    info: {
      color: theme.palette.secondary.dark,
      marginTop: 1,
      marginBottom: 10,
      //background: theme.palette.secondary.light,
      borderRadius: 4,
      fontSize: theme.fontSizes.smallFont,
      padding: 5,
      fontFamily: theme.fontFamily,
      textAlign: "center",
    },
    noError: {
      visibility: "hidden",
      marginTop: 1,
      marginBottom: 10,
      padding: 5,
    },
  });

const aErrorView = function (props: {
  error?: Error | null;
  defaultErrorMessage?: string | null;
  message?: string | null;
  info?: boolean | null;
  classes: any;
}) {
  const { error, classes, defaultErrorMessage, info } = props;

  let errorMessage: string | null = null;
  if (props.message) {
    errorMessage = props.message;
  } else if (error != null && error.error_code) {
    errorMessage = intl.get(`error.code.${error.error_code}`);
    if (errorMessage == null || errorMessage.length === 0) {
      if (defaultErrorMessage != null) {
        errorMessage = `${defaultErrorMessage}`;
      } else {
        errorMessage = error.error_code;
      }
    }
  } else if (error != null && error.error) {
    errorMessage = error.error;
  } else if (error != null && defaultErrorMessage) {
    errorMessage = defaultErrorMessage;
  } else if (error != null) {
    errorMessage = intl.get("error.generic");
  }

  if (errorMessage != null) {
    return (
      <div className={info === true ? classes.info : classes.error}>
        {errorMessage}
      </div>
    );
  }
  return <div className={classes.noError}>xxxxx</div>;
};

export default withStyles(styles)(aErrorView);
export const ErrorView = withStyles(styles)(aErrorView);
