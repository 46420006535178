import { RouteComponentProps } from "react-router";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";
import * as React from "react";
import { Progress } from "../components";
import axios from "axios";
import { generateSimpleDeviceFingerprint } from "../actions/auth";
import { getParamValue } from "../utils/utils";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "stretch",
      marginTop: "auto",
      marginBottom: "auto",
      flexGrow: 1,
      paddingTop: 40,
      paddingBottom: 40,
      paddingLeft: 20,
      paddingRight: 20,
    },
    topImage: {
      marginTop: 0,
      marginBottom: 30,
      maxWidth: 300,
    },
  });

export interface Props
  extends RouteComponentProps<void>,
    WithStyles<typeof styles> {
  theme: any;
}

class AutoValidationPage extends React.Component<Props> {
  componentDidMount() {
    this.autoValidate();
  }

  async autoValidate() {
    try {
      const redirectUri = getParamValue("redirect_uri")!;
      const codeChallenge = getParamValue("code_challenge")!;
      const legacyAccessToken = getParamValue("legacy_access_token");

      let headers = {};
      if (legacyAccessToken !== null) {
        headers = { Authorization: `Bearer ${legacyAccessToken}` };
      }
      const res = await axios.post(
        "/api/auth2/b2c/auto",
        {
          sessionDeviceFingerprint: generateSimpleDeviceFingerprint(),
          redirect_uri: redirectUri,
          code_challenge: codeChallenge,
        },
        { withCredentials: true, headers: headers },
      );

      if (res.data.authorization_code) {
        if (redirectUri.indexOf("?") !== -1) {
          window.location.replace(
            `${redirectUri}&authorization_code=${res.data.authorization_code}`,
          );
        } else {
          window.location.replace(
            `${redirectUri}?authorization_code=${res.data.authorization_code}`,
          );
        }
      }
    } catch (error) {}
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        {this.props.theme!.landingPage.topImage && (
          <img
            src={this.props.theme!.landingPage.topImage}
            alt=""
            className={this.props.classes.topImage}
            style={{ marginBottom: 10 }}
          />
        )}

        <Progress marginTop={20} />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AutoValidationPage);
