import { BcThemeOptions } from "../themeBase";

export const Stadtmama: BcThemeOptions = {
  colors: {
    primary: "#a0a0a0",
    primaryLight: "#a0a0a0",
    primaryDark: "#a0a0a0",
    primaryContrast: "#fff",
    primaryLightContrast: "#fff",
    secondaryBackground: "#fff",
    bodyText: "#333333",
    background: "#fff",
    indicatorProfile: "#d12e26",
    indicatorFavorite: "#d12e26",
    heroBackground: "rgba(255,255,255,.9)",
    heroText: "#776666",
  },
  styles: {
    headerStyle: "centerBanner",
    authPageStyle: "flat",
    logoStyle: "negative",
  },
  images: {
    landing: "/themes/Stadtmama_Logo_rund.png",
    banner: "/themes/Stadtmama_Logo.jpg",
  },
  misc: {
    originUrl: "https://stadtmama.at",
    landingPageIconHeight: 0,
    topBarIconHeight: 90,
  },
};
